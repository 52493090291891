// Import Core
import React, { useEffect } from 'react';
import styled from 'styled-components';
// Import Components
import { Button } from '../ui/Button';
// Import Icons
import { FaBars as MenuIcon, FaSearch as SearchIcon, FaLocationArrow as HotelsNearMeIcon, FaSearch } from 'react-icons/fa';
// Import Utils
import { GetListLinks } from '../../utils/getNavItemLinks';
import { Link } from 'gatsby';
import CollapsibleDropdown from '../ui/CollapsibleDropdown';
import NavSearchInput from './NavSearchInput';
import { useMediaQuery } from 'react-responsive';

/* Styled Components */
const NavStyles = styled.nav`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 32px;
	@media (max-width: 768px) {
		gap: 0;
	}
	ul {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		gap: 32px;
		text-decoration: none;
		list-style-type: none;
		color: var(--white);
		font-size: 0.778rem; //14px
		font-weight: 300;
		@media (max-width: 1200px) {
			gap: 16px;
			margin-left: 16px;
		}
		@media (max-width: 992px) {
			gap: 24px;
			margin-left: unset;
		}
		@media (max-width: 768px) {
			display: none;
		}
		li {
			@media (max-width: 1000px) {
				max-width: min-content;
				text-align: center;
			}
			@media (max-width: 992px) {
				max-width: unset;
				text-align: unset;
			}
			a {
				color: inherit;
				font-size: inherit;
				font-weight: inherit;
				transition: all 0.3s ease-in-out;
				&:hover {
					text-decoration: underline;
				}
				p {
					text-align: center;
				}
			}
		}
	}
	.nav-button-group {
		display: flex;
		gap: 0;
		.primary-btn {
			border-top: 1px solid var(--primary-300);
			border-bottom: 1px solid var(--primary-300);
			border-left: 1px solid var(--primary-300);
		}
		.hotels-near-me-btn {
			display: flex;
			a {
				color: var(--white);
				font-size: inherit;
				text-decoration: none;
				line-height: 0;
				padding: 25% 0;
				@media (max-width: 992px) {
					/* line-height: 0; */
					padding: 0;
				}
			}
		}
		.search-btn {
			display: none;
		}
		@media (max-width: 992px) {
			.search-btn {
				display: flex;
			}
			.main-menu-btn {
				span {
					display: none;
				}
			}
		}
	}

	.collapsible-search {
		display: none;
		.Collapsible__trigger {
			display: flex;
			&.is-open {
				.search-btn {
					background: var(--primary-300);
				}
	
			+ .Collapsible__contentOuter {
				.mobile-search {
					/* position: relative; */
					> div {
						display: inherit;
						position: absolute;
						/* top: 0; */
						left: 0;
						right: 0;
						margin: 0 35px;
						width: calc(100% - 70px);
						
						.search-input {
							display: flex;
							padding: 0px 12px 10px 12px;
							width: calc(100% - 24px);
							border-radius: 0px 0px 8px 8px;
							background: var(--primary-500);
							.search-icon-button {
								left: calc(10px + 12px);
							}
							input {
								width: 100%;
							}
						}
						.instant-search-wrapper {
							top: unset;
							width: 100%;
							.instant-search-results {
								border-top: 10px solid var(--primary-500);
								margin-top: -10px;
								width: calc(100% - 24px);
								max-height: 200px;
								top: unset;
								left: unset;
							}
						}
					}
				}
			}
			
		}
	}
	@media (max-width: 992px) {
		display: flex;
	}	
}
`;

/* Render Component */
const Nav = ({ navbarOpen, setNavbarOpen, mainNav, searchValue, setSearchValue }) => {
	// On Page load get height of header so we can set the height of the mobile search input
	const isMobile = useMediaQuery({ query: `(max-width: 992px)` });
	
	const handleResize = () => {
		const height = document.getElementsByTagName('header')[0].clientHeight;
		
		if(isMobile) {
			document.querySelectorAll('.mobile-search div')[0].style.top = height + 'px';
		} else {
			document.querySelectorAll('.instant-search-wrapper')[0].style.top = `calc(${height}px - 32px)`;
		}
	}
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize, false);
	}, [isMobile])
	
	return (
		<NavStyles>
			{mainNav && mainNav.items.length > 0 && (
				<ul>
					{mainNav.items.map((item, index) => {
						return <GetListLinks item={item} index={index} key={index} />;
					})}
				</ul>
			)}
			<div className="nav-button-group">
				{/* Hotels near me */}
				<Button
					className='hotels-near-me-btn primary-btn small'
					aria-label='Hotels Near Me'
				>
					<Link className="hotels-near-me" to="/hotels-near-me">
						<HotelsNearMeIcon className="hotels-near-me-btn_icon" />
					</Link>
				</Button>
				{/* TODO:  Search on mobile */}
				{/* <Button
					className='search-btn primary-btn small'
					onClick={() => setNavbarOpen(!navbarOpen)}
					aria-label='Search Site'
				>
					<SearchIcon className="search-btn_icon" />
				</Button> */}
			<div className="collapsible-search">
				<CollapsibleDropdown
					triggerElementProps={{id: 'collapsible-search-trigger'}}
					lazyRender={true}
					trigger={
						<Button
							className='search-btn primary-btn small'
							// onClick={() => setNavbarOpen(!navbarOpen)}
							aria-label='Search Site'
						>
							<SearchIcon className="search-btn_icon" />
						</Button>
					}
					triggerWhenOpen={
						<Button
							id='collapsible-search-btn'
							className='search-btn primary-btn small'
							// onClick={() => setNavbarOpen(!navbarOpen)}
							aria-label='Search Site'
						>
							<SearchIcon className="search-btn_icon" />
						</Button>
					}
					content={
						<div className="mobile-search">
							<NavSearchInput
								placeholder='Search this site...'
								icon={<FaSearch />}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
							/>
						</div>
					}
				/>
				</div>
			
				<Button
					className='white-btn small main-menu-btn'
					onClick={() => setNavbarOpen(!navbarOpen)}
					aria-label='Main Menu'
				>
					<MenuIcon />
					<span>Menu</span>
				</Button>
			</div>
		</NavStyles>
	);
};

export default Nav;
