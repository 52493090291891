// Import Core
import React from "react";
import styled from 'styled-components';
// Import Components
import SectionTitle from "../ui/SectionTitle";
import VoucherForm from "../ui/Forms/VoucherForm";
// import ContactForm from "../ui/Forns/ContactForm";

/* Styled Components */
const FormStyles = styled.div`
.section-title {
    text-align: center;
  }
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      padding: 80px;
      width: 992px;
      @media (max-width: 575px) {
        padding: 24px;
      }
      >* {
        width: 100%;
        margin: 12px 0;
      }
      .two-col {
        width: 100%;
        display: flex;
        gap: 36px;
        >* {
          width: 50%;
        }
        @media (max-width: 575px) {
          flex-direction: column;
          >* {
            width: 100%;
          }
        }
      }
      input, textarea {
        background-color: var(--white);
        width: calc(100% - 24px);
        &:focus {
          outline: none;
        }
      }
      textarea {
        border: 1px solid var(--neutral-300);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        min-height: 132px;
        width: calc(100% - 20px);
      }
      .dropdown-container, .dropdown-container {
        width: 100%;
        text-transform: none;
        line-height: 128%;
        .dropdown-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--neutral-300);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        padding: 10px;
        position: relative;
        cursor: pointer;
          &.selected {
            color: var(--body-color);
          }
        }
        svg {
            font-size: 12px;
          }
        .dropdown-list-container {
          width: 100%;
          position: relative;
          .dropdown-list {
            padding: 0;
            margin: 0;
            border: 1px solid var(--neutral-300);
            position: absolute;
            background-color: var(--white);
            width: inherit;
            max-height: 240px;
            overflow-y: scroll;
            z-index: 2;
            .list-item {
              list-style: none;
              padding: 0.2em 10px;
              cursor: pointer;
              &:hover {
                background-color: var(--neutral-50);
              }
            }
          }
        }
      }
      .consent {
        display: flex;
        input {
          width: auto;
          margin-right: 10px;
        }
      }
      .btn {
        display: flex;
        justify-content: end;
        @media (max-width: 575px) {
          margin-top: 40px;
        }
        button {
          padding: 13px 16px 12px;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          color: var(--white);
          background: var(--primary-500);
          &:hover {
            cursor: pointer;
            background: var(--primary-400);
          }
          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
      .error-msg {
        color: var(--error-500);
        font-size: 0.8rem;
      }
      .loading {
        position : fixed;
        top: 50%;
        left: 50%;
        transform: translate(50%, 50%);
      }
    }
  }
`;

const Form = ({ location }) => {
  return (
    <FormStyles>
      <SectionTitle subtitle='Vouchers' title='Buy A Voucher' className='section-title' />
      <VoucherForm location={location} />
      {/* <ContactForm /> */}
    </FormStyles>
  )
}

export default Form