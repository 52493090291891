// Import Core
import React from "react";
import { Link, navigate } from "gatsby";
// Import Components
import Form from '../components/blocks/Form';
import { Button } from "../components/ui/Button";
import Modal from "../components/ui/Modal";
import ContactForm from '../components/ui/Forms/ContactForm';
import NewsletterForm from "../components/ui/Forms/NewsletterForm";
import SubmitReviewForm from "../components/ui/Forms/SubmitReviewForm";
// Import Utils
import { getSlug } from '../utils/getSlug';
import useModal from '../utils/useModal';

const getNavItemLinks = (navItem) => {

	const displayExternalLink = navItem.navigationItemUrl.displayExternal;
	const internalLink = navItem.navigationItemUrl.internalLink;
	const externalUrl = navItem.navigationItemUrl.externalUrl;
	// Depending on displayExternal checkbox link will be internal or external
	const link =
		displayExternalLink === true
			? externalUrl
			: internalLink;

	return {
		displayExternalLink: displayExternalLink, 
		link: link
	}

}

// If Get Links is to return a button
export const GetLinks = ({ navItem, buttonStyle, location }) => {
	const [isModalOpen, toggle] = useModal();
	
		const {displayExternalLink, link} = getNavItemLinks(navItem);
		if(navItem.addForm) {
			if(navItem.form.formOptions === 'voucherForm') {
				return (
					<>
						<Button
							onClick={toggle}
							role="link"
							className={buttonStyle}
						>
							{navItem.name}
						</Button>

						<Modal
							id='voucher-form'
							isModalOpen={isModalOpen}
							hide={toggle}
							className='modal-form'
							content={<div><Form location={location} /></div>}
						/>
					</>
				)
			} else if(navItem.form.formOptions === 'contactForm') {
				return (
					<>
						<Button
							onClick={toggle}
							role="link"
							className={buttonStyle}
						>
							{navItem.name}
						</Button>

						<Modal
							id='contact-form'
							isModalOpen={isModalOpen}
							hide={toggle}
							className='modal-form'
							content={<div><ContactForm /></div>}
						/>
					</>
				);
			} else if(navItem.form.formOptions === 'newsletterForm') {
				return (
					<>
					<Button
						onClick={toggle}
						role="link"
						className={buttonStyle}
					>
						{navItem.name}
					</Button>

					<Modal
						id='contact-form'
						isModalOpen={isModalOpen}
						hide={toggle}
						className='modal-form'
						content={<div><NewsletterForm /></div>}
					/>
				</>
				)
			}else if(navItem.form.formOptions === 'submitReviewForm') {
				return (
					<>
					<Button
						onClick={toggle}
						role="link"
						className={buttonStyle}
					>
						{navItem.name}
					</Button>

					<Modal
						id='contact-form'
						isModalOpen={isModalOpen}
						hide={toggle}
						className='modal-form'
						content={<div><SubmitReviewForm /></div>}
					/>
				</>
				)
			}
		} else {

			if(navItem.name && link) {
				// Two options:
				// 1. Internal Link
				if (displayExternalLink === false && link !== null) {
					const slug = getSlug(link)
					return(
						<Button
							onClick={()=>{navigate(slug)}}
							role="link"
							className={buttonStyle}
						>
							{navItem.name}
						</Button>
					)
				}
				// 2. External Link
				else if (displayExternalLink === true && link !== null) {
					return(
						<a href={link} target='_blank'>
							<Button
								className={buttonStyle}
							>
								{navItem.name}
							</Button>
						</a>
					)
				} 
			} else {
				return null
			}
		}
	}


// If navItems is a list item return this
export const GetListLinks = ({item, index, isMainMenu, setNavbarOpen}) => {

	let displayExternalLink, link;
	if(item.navigationItemUrl) {
		displayExternalLink = getNavItemLinks(item).displayExternalLink
		link = getNavItemLinks(item).link
	} else {
		link = item
	}
	const newSlug = getSlug(link);

	const handleClick = () => {
		setNavbarOpen(false);
	}

	// Two options:
	// 1. Internal Link
	if (displayExternalLink === false && link !== null) {
		return (
			<li key={index} onClick={isMainMenu ? handleClick : undefined}>
				<Link to={newSlug}>
					<p className="small">{item.name}</p>
				</Link>
			</li>
		);
	}
	// 2. External Link
	else if (displayExternalLink === true && link !== null) {
		return (
			<li key={index}>
				<a href={link} target="_blank">
					<p className="small">{item.name}</p>
				</a>
			</li>
		);
	} else {
		return (
			<li key={index}>
				<Link to={newSlug}>
					<p className="small">{item.name}</p>
				</Link>
			</li>
		);
	}
};

export default getNavItemLinks