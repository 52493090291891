import { useEffect } from 'react';
import { useState } from 'react';
// Import Utils
import useScrollBlock from '../utils/useScrollBlock'
const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    // Prevent scrolling when modal is open
    // isModalOpen ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
    isModalOpen ? blockScroll() : allowScroll();
  }, [isModalOpen, blockScroll, allowScroll])

  function toggle() {
    setIsModalOpen(!isModalOpen);
    
  }

  return [
    isModalOpen,
    toggle,
  ]
  
};

export default useModal;