
// OnClick handler - need to get attributes and pass to datalayer
// JSON OBJECT OF DATA - TO URL
/*
hotel name
slug
button clicked - booking.com or direct, see website, email
timestamp
*/

const handleTrackingLinkClicks = (linkType, hotelName, slug) => {
  const timestamp = new Date(); 
	
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({
		"hotel_name": hotelName,
		"dateStamp": timestamp,
		"slug": slug,
		"ButtonClick": linkType
	});

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};
	window.dataLayer.push({'event':'link_click', 'hotel_name':hotelName,'dateStamp':timestamp,'pageSlug':slug,'click_type':linkType});
	fetch("https://loqvlcodu3.execute-api.eu-west-2.amazonaws.com/post/ghg", requestOptions)
		.then(response => response.text())
		.then(result => console.log(result))
		.catch(error => console.log('error', error));
}

export default handleTrackingLinkClicks