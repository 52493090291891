// Import Core
import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
// Import Libraries
import { useForm } from "react-hook-form";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// Import Components
import SectionTitle from "../SectionTitle";
// Import Utils
import { getCurrentDate } from "../../../utils/getCurrentDate";
// Import Assets
// import phoneCodes from '../../../utils/phoneCodes'
// Import Icons
import { FaChevronDown } from "react-icons/fa";

/* Styled Components */
const ContactFormStyles = styled.div`
  .section-title {
    text-align: center;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      padding: 80px;
      width: 992px;
      @media (max-width: 575px) {
        padding: 24px;
      }
      > * {
        width: 100%;
        margin: 12px 0;
      }
      .two-col {
        width: 100%;
        display: flex;
        gap: 36px;
        > * {
          width: 50%;
        }
        @media (max-width: 575px) {
          flex-direction: column;
          > * {
            width: 100%;
          }
        }
      }
      input,
      textarea {
        background-color: var(--white);
        width: calc(100% - 24px);
        &:focus {
          outline: none;
        }
      }
      textarea {
        border: 1px solid var(--neutral-300);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        min-height: 132px;
        width: calc(100% - 20px);
      }
      .dropdown-container,
      .dropdown-container {
        width: 100%;
        text-transform: none;
        line-height: 128%;
        .dropdown-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid var(--neutral-300);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          padding: 10px;
          position: relative;
          cursor: pointer;
          &.selected {
            color: var(--body-color);
          }
        }
        svg {
          font-size: 12px;
        }
        .dropdown-list-container {
          width: 100%;
          position: relative;
          .dropdown-list {
            padding: 0;
            margin: 0;
            border: 1px solid var(--neutral-300);
            position: absolute;
            background-color: var(--white);
            width: inherit;
            max-height: 240px;
            overflow-y: scroll;
            z-index: 2;
            .list-item {
              list-style: none;
              padding: 0.2em 10px;
              cursor: pointer;
              &:hover {
                background-color: var(--neutral-50);
              }
            }
          }
        }
      }
      .consent {
        display: flex;
        input {
          width: auto;
          margin-right: 10px;
        }
      }
      .btn {
        display: flex;
        justify-content: end;
        @media (max-width: 575px) {
          margin-top: 40px;
        }
        button {
          padding: 13px 16px 12px;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          color: var(--white);
          background: var(--primary-500);
          &:hover {
            cursor: pointer;
            background: var(--primary-400);
          }
          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
      .error-msg {
        color: var(--error-500);
        font-size: 0.8rem;
      }
      .loading {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(50%, 50%);
      }
    }
  }
`;

const ContactForm = () => {
  // Initiate forms
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // Create useStates for the custom dropdowns
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  // Used to check for clicks outside of dropdowns
  const node = useRef();

  // Set subject dropdown options
  const subjectArray = ["option 1", "option 2", "option 3"];

  // Toggle open/close of dropdown lists
  const toggle = () => () => {
    setSubjectDropdownOpen(!subjectDropdownOpen);
  };

  // Updates useState values when dropdown option clicked
  const onOptionClicked = (option) => () => {
    setSelectedSubject(option);
    // Close dropdowns
    setSubjectDropdownOpen(false);
  };

  // Handle form submit
  const handlePost = async (formData) => {
    // Create unique form ID
    const uuid = uuidv4();
    formData.uuid = uuid;

    // Set current date and dropdown values
    formData.subject = selectedSubject;
    formData.date = getCurrentDate();

    // Send data to sanity
    const api =
      "https://1gqu4qfy3g.execute-api.eu-west-2.amazonaws.com/staging/";
    axios
      .post(api, { formType: "new-form", body: formData })
      .then((response) => {
        console.log("response", response);
        // setLoading(false);
        navigate("/form-confirmation/?type=contact&status=success");
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
        navigate("/form-confirmation/?type=contact&status=fail");
      });
  };

  return (
    <ContactFormStyles>
      <SectionTitle
        subtitle="Contact"
        title="Contact Us Today"
        className="section-title"
      />
      <div>
        <form
          onSubmit={handleSubmit(handlePost)}
          name="contact-form"
          method="POST"
          honeypot="got-ya"
          id="contact-form"
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <input
            type="hidden"
            name="formId"
            value="contact-form"
            {...register("formId")}
          />

          <div className="two-col">
            <label htmlFor="name">
              <p>Your Name</p>
              {errors && errors.name && (
                <p className="error-msg" role="alert">
                  Please complete this field
                </p>
              )}
              <input name="name" {...register("name", { required: true })} />
            </label>
            <label htmlFor="email">
              <p>Email address</p>
              {errors && errors.email && (
                <p className="error-msg" role="alert">
                  Please complete this field
                </p>
              )}
              <input
                name="email"
                placeholder="your@email.com"
                {...register("email", {
                  required: true,
                  pattern:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                })}
              />
            </label>
          </div>

          <div className="two-col">
            {/* PHONE NUMBER FIELD */}
            <label htmlFor="telephone">
              <p>Phone number</p>
              {errors && errors.telephone && (
                <p className="error-msg" role="alert">
                  Please complete this field
                </p>
              )}
              <input
                name="telephone"
                placeholder="+1 (123) 456-7890"
                {...register("telephone", { required: true })}
              />
            </label>
            {/* SUBJECT FIELD */}
            {/* <label htmlFor="Subject">
              <p>Subject</p> */}
              {/* Register the input type */}
              {/* <input
                type="hidden"
                name="subject"
                value={selectedSubject}
                {...register("subject")}
              /> */}
              {/* Render custom select dropdown */}
              {/* <div className="dropdown-container subject">
                <div
                  className={`dropdown-header container ${
                    selectedSubject ? "selected" : null
                  }`}
                  onClick={toggle("value")}
                >
                  {selectedSubject ? selectedSubject : "--Choose--"}
                  <FaChevronDown />
                </div>
                {subjectDropdownOpen && (
                  <div className="dropdown-list-container">
                    <ul className="dropdown-list" ref={node}>
                      {subjectArray.map((option, index) => (
                        <li
                          className="list-item"
                          onClick={onOptionClicked(option, "subject")}
                          key={index}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </label> */}
          </div>

          <label htmlFor="message">
            <p>Message</p>
            {errors && errors.message && (
              <p className="error-msg" role="alert">
                Please complete this field
              </p>
            )}
            <textarea
              name="message"
              {...register("message", { required: true })}
            />
          </label>

          <label className="consent" htmlFor="consent">
            <input type="checkbox" name="consent" {...register("consent")} />
            <p>Sign me up for the newsletter</p>
          </label>
          <label className="consent checkbox" htmlFor="consent">
            {errors && errors.consent && (
              <p className="error-msg" role="alert">
                Please accept the privacy policy.{" "}
              </p>
            )}
            <input
              type="checkbox"
              name="consent"
              {...register("consent", {
                required: true,
              })}
            />
            <p>
              I accept the GHG's <a href={"/privacy-policy"}>privacy policy</a>{" "}
              and agree to the terms and conditions (see below)*
            </p>
          </label>

          {/* HIDDEN FIELDS */}
          <input type="hidden" name="date" value="" {...register("date")} />
          <input type="hidden" name="uuid" value="" {...register("uuid")} />

          {/* HONEYPOT */}
          <label
            htmlFor="got-ya"
            style={{
              position: "absolute",
              overflow: "hidden",
              clip: "rect(0 0 0 0)",
              height: "1px",
              width: "1px",
              margin: "-1px",
              padding: "0",
              border: "0",
            }}
          >
            Don’t fill this out if you're human:
            <input tabIndex="-1" name="got-ya" {...register("got-ya")} />
          </label>

          <div className="btn large">
            <button type="submit">SUBMIT FORM</button>
          </div>
        </form>
      </div>
    </ContactFormStyles>
  );
};

export default ContactForm;
