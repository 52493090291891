// Import Core
import React, { Suspense, lazy, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
// Import Libraries
import { AnchorLink } from "gatsby-plugin-anchor-links";
// Import Components
// import Footer from './base/Footer';
import Header from "./base/Header";
import SearchBar from "./base/SearchBar";
import SeeHotelWebsiteCTA from "./base/SeeHotelWebsiteCTA";
// import CTA from './blocks/CTA';
// import DoubleCTA from './blocks/DoubleCTA';
import AboutGuide from "./blocks/AboutGuide";
import FloatingButton from "./blocks/FloatingButton";
import CollapsibleDropdown from "./ui/CollapsibleDropdown";
import Loader from "./blocks/Loader";
// Import Styles
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
// Lazy Load Components
const CTA = lazy(() => import("./blocks/CTA"));
const DoubleCTA = lazy(() => import("./blocks/DoubleCTA"));
const Footer = lazy(() => import("./base/Footer"));

/* Styled Components */
const CollapsibleDropdownStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  .about-guide-collapsible {
    width: 100%;
    position: absolute;
    overflow: scroll;
  }
`;

const MainStyle = styled.main`
  position: relative;
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between;
  .anchor-link-btn {
    position: absolute;
    width: 100%;
  }
`;

/* Components */
// If homepage - about btn scrolls down to section, otherwise show collapsible dropdown
const AboutGuideButton = ({ data, location }) => {
  if (location.pathname === "/") {
    return (
      <AnchorLink
        to="/#anchor_about-guide"
        children={
          <FloatingButton
            title={data.siteSettings.dropdownButtonTitle}
            location={location}
          />
        }
        className="anchor-link-btn"
        title={data.siteSettings.dropdownButtonTitle}
      />
    );
  } else {
    return (
      <CollapsibleDropdownStyles>
        <CollapsibleDropdown
          triggerElementProps={{ id: "collapsible-dropdown-trigger" }}
          lazyRender={true}
          trigger={
            <FloatingButton
              title={data.siteSettings.dropdownButtonTitle}
              location={location}
            />
          }
          triggerWhenOpen={
            <FloatingButton
              title={data.siteSettings.dropdownButtonTitle}
              location={location}
            />
          }
          content={
            <AboutGuide
              aboutGoodHotelGuide={data.siteSettings.aboutGoodHotelGuide}
              className="about-guide-collapsible"
            />
          }
        />
      </CollapsibleDropdownStyles>
    );
  }
};

/* Render Component */
// props.children destructured to children
// Add a StaticQuery to pass siteSettings to Collapsible Component
const Layout = ({ children, location }) => {
  let hotelPage = null;
  if (children.props && children.props.data && children.props.data.hotels) {
    hotelPage = children.props.data.hotels;
  }
  const [searchValue, setSearchValue] = useState("");

  // TODO:  MAKE A LAYOUT FRAGMENT
  const data = useStaticQuery(
    graphql`
      query HeadingQuery {
        siteSettings: sanitySiteSettings {
          id
          mainImage {
            asset {
              gatsbyImageData(width: 300, height: 75, placeholder: BLURRED)
            }
          }
          # mainImageMobile {
          # 	asset {
          # 		gatsbyImageData(width: 75, height: 75, placeholder: BLURRED)
          # 	}
          # }
          mainNav {
            title
            items {
              name
              navigationItemUrl {
                displayExternal
                externalUrl
                internalLink {
                  ... on SanityBlog {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                  }
                  ... on SanityHotel {
                    _id
                    _type
                    name
                    hotelParent: parentPage
                    slug {
                      current
                    }
                  }
                  ... on SanityPillar {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
          hamburgerMenu {
            title
            displayList
            items {
              name
              navigationItemUrl {
                displayExternal
                externalUrl
                internalLink {
                  ... on SanityBlog {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                  }
                  ... on SanityHotel {
                    _id
                    _type
                    name
                    hotelParent: parentPage
                    slug {
                      current
                    }
                  }
                  ... on SanityPillar {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
            itemsList {
              name
              items {
                name
                navigationItemUrl {
                  displayExternal
                  externalUrl
                  internalLink {
                    ... on SanityBlog {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                    }
                    ... on SanityHotel {
                      _id
                      _type
                      name
                      hotelParent: parentPage
                      slug {
                        current
                      }
                    }
                    ... on SanityPillar {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                      parentPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          dropdownButtonTitle
          aboutGoodHotelGuideExperts
          #### TODO:  AboutGuideFragment
          aboutGoodHotelGuide {
            aboutGoodHotelGuideDescription
            navItem {
              name
              navigationItemUrl {
                displayExternal
                externalUrl
                internalLink {
                  ... on SanityBlog {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                  }
                  ... on SanityHotel {
                    _id
                    _type
                    name
                    hotelParent: parentPage
                    slug {
                      current
                    }
                  }

                  ... on SanityPillar {
                    id
                    _type
                    name
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
              }
              addForm
              form {
                _type
                formOptions
              }
            }
            subheading
            title
            featureList {
              _key
              featureIcon {
                name
              }
              featureName
              featureDescription
            }
          }
          #### End of AboutGuideFragment
          # CTA
          cta {
            sectionTitle {
              subheading
              title
              caption
              navItem {
                name
                navigationItemUrl {
                  displayExternal
                  externalUrl
                  internalLink {
                    ... on SanityBlog {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                    }
                    ... on SanityHotel {
                      _id
                      _type
                      name
                      hotelParent: parentPage
                      slug {
                        current
                      }
                    }
                    ... on SanityPillar {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                      parentPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
                addForm
                form {
                  _type
                  formOptions
                }
              }
            }
            addImage
            backgroundImage {
              asset {
                gatsbyImageData(width: 1200, height: 350, placeholder: BLURRED)
              }
            }
          }
          # pre footer - double CTA
          doubleFooterCta {
            sectionTitle {
              subheading
              title
              caption
              navItem {
                name
                navigationItemUrl {
                  displayExternal
                  externalUrl
                  internalLink {
                    ... on SanityBlog {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                    }
                    ... on SanityHotel {
                      _id
                      _type
                      name
                      hotelParent: parentPage
                      slug {
                        current
                      }
                    }
                    ... on SanityPillar {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                      parentPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
                addForm
                form {
                  _type
                  formOptions
                }
              }
            }
            addImage
            backgroundImage {
              asset {
                gatsbyImageData(width: 1100, height: 700, placeholder: BLURRED)
              }
            }
          }
          # Footer
          footer {
            sponsors {
              image {
                asset {
                  gatsbyImageData(
                    width: 100
                    # height: 100,
                    placeholder: BLURRED
                  )
                }
              }
              navItem {
                name
                navigationItemUrl {
                  # TODO: Is there a way to condense this?
                  displayExternal
                  externalUrl
                  internalLink {
                    ... on SanityBlog {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                    }
                    ... on SanityHotel {
                      _id
                      _type
                      name
                      hotelParent: parentPage
                      slug {
                        current
                      }
                    }
                    ... on SanityPillar {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                      parentPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
                addForm
                form {
                  _type
                  formOptions
                }
              }
            }
            footerMenus {
              title
              items {
                name
                navigationItemUrl {
                  displayExternal
                  internalLink {
                    ... on SanityBlog {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                    }
                    ... on SanityHotel {
                      _id
                      _type
                      name
                      hotelParent: parentPage
                      slug {
                        current
                      }
                    }
                    ... on SanityPillar {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                      parentPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                  externalUrl
                }
              }
            }
            # Policy Links
            policyLinks {
              title
              items {
                name
                navigationItemUrl {
                  displayExternal
                  externalUrl
                  internalLink {
                    ... on SanityBlog {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                    }
                    ... on SanityHotel {
                      _id
                      _type
                      name
                      hotelParent: parentPage
                      slug {
                        current
                      }
                    }
                    ... on SanityPillar {
                      _id
                      _type
                      name
                      slug {
                        current
                      }
                      parentPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          # TODO: Socials
          socialLinks {
            _key
            socialIcon {
              name
              provider
            }
            navItem {
              name
              navigationItemUrl {
                displayExternal
                externalUrl
                internalLink {
                  ... on SanityBlog {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                  }
                  ... on SanityHotel {
                    _id
                    _type
                    name
                    hotelParent: parentPage
                    slug {
                      current
                    }
                  }
                  ... on SanityPillar {
                    _id
                    _type
                    name
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
              }
              addForm
              form {
                _type
                formOptions
              }
            }
          }
        }
      }
    `
  );
  // TODO: Can this be a SiteSettings Field etc?
  return location.pathname.includes("/newsletter/") ? (
    <div>{children}</div>
  ) : (
    <div>
      <GlobalStyles />
      <Typography />
      <Header
        logo={data.siteSettings.mainImage.asset}
        // logoMobile={data.siteSettings.mainImageMobile.asset}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        mainNav={data.siteSettings.mainNav}
        hamburgerMenu={data.siteSettings.hamburgerMenu}
      />
      {/* If page is hotel then show the show hotel website button instead of search bar */}
      {
        hotelPage && hotelPage._type && hotelPage._type === "hotel" ? (
          <SeeHotelWebsiteCTA
            website={hotelPage.contactGroup.websiteUrl}
            hasPaid={hotelPage.displayOptions.paid}
            bookingUrl={hotelPage.contactGroup.bookingUrl}
            hotelName={hotelPage.name}
            slug={hotelPage.slug.current}
          />
        ) : null
        // <SearchBar />
      }

      <MainStyle>
        <AboutGuideButton location={location} data={data} />
        {children}
      </MainStyle>

      {data.siteSettings.cta && data.siteSettings.cta.sectionTitle.title && (
        <Suspense fallback={<Loader />} key="cta">
          <CTA cta={data.siteSettings.cta} />
        </Suspense>
      )}
      {/* Hiding Double CTA for now */}
      {data.siteSettings.doubleFooterCta && (
        <Suspense fallback={<Loader />} key="double-cta">
          <DoubleCTA doubleCta={data.siteSettings.doubleFooterCta} />
        </Suspense>
      )}
      <Suspense fallback={<Loader />} key="footer">
        <Footer
          footer={data.siteSettings.footer}
          socialLinks={data.siteSettings.socialLinks}
          logo={data.siteSettings.mainImage.asset}
          // logoMobile={data.siteSettings.mainImageMobile.asset}
        />
      </Suspense>
    </div>
  );
};

export default Layout;
