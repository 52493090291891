// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Utils
import { renderFontAwesomeIcon } from '../../utils/renderFontAwesomeIcon';

/* Styled Components */
const FeatureListStyles = styled.section`
	padding: 64px 120px;
	gap: 36px;
	background: var(--primary-500);
	color: var(--white);
	@media (max-width: 992px) {
		padding: 40px 64px;
		color: var(--white);
	}
	@media (max-width: 768px) {
		padding: 40px 24px;
	}
	ul {
		list-style-type: none;
		display: flex;
		gap: 36px;
		justify-content: space-between;
		@media (max-width: 768px) {
			flex-direction: column;
		}
		li {
			width: 376px;
			@media (max-width: 768px) {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			.icon {
				width: 100px;
				height: 100px;
				background: rgba(0, 0, 0, 0.03);
				border-radius: 1000px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				margin-bottom: 24px;
				@media (max-width: 768px) {
					width: 65px;
					height: 65px;
					font-size: 20px;
					margin-bottom: 0;
					margin-right: 16px;
				}
			}
			.feature-list_content {
				.title {
					margin-bottom: 16px;
				}
			}
		}
	}
`;

/* Render Component */
const FeatureList = ({ featureList }) => {
	return (
		<FeatureListStyles className='ft-list'>
			<ul>
				{featureList.map((item) => {
					const Icon = renderFontAwesomeIcon(item.featureIcon.name);
					return (
						<li key={item._key}>
							<div className='icon'>
								{/* <Icon /> */}
								{Icon}
							</div>
							<div className='feature-list_content'>
								<h4 className='title'>{item.featureName}</h4>
								<p className='description small'>{item.featureDescription}</p>
							</div>
						</li>
					);
				})}
			</ul>
		</FeatureListStyles>
	);
};

export default FeatureList;
