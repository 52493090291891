const getHotelsOrPagesFromIds = (hotelsOrPages, arrOfIds) => {
  // remove any duplicates if any exist
  arrOfIds = [...new Set(arrOfIds)];

  // -> Now we have arrOfIds - these are just ids so we need to get all hotel info by matching id
  const filteredResults = arrOfIds
    .map((id) => hotelsOrPages.length > 0 ? hotelsOrPages.filter((hotelOrPage) => hotelOrPage._id === id) : false).flat();
  return filteredResults
} 

export { getHotelsOrPagesFromIds }