// Import Core
import React, { useRef } from "react";
import styled from 'styled-components';
// Import Libraries
// import { loadStripe } from '@stripe/stripe-js'
import { useStaticQuery, graphql } from "gatsby";


/* Styled Components */
const ProductListStyles = styled.div``;

const ProductList = ({ valueDropdownOpen, onOptionClicked }) => {
  // Used to check for clicks outside of dropdowns
	const node = useRef();

  const data = useStaticQuery(graphql`
        query ProductDetails {
      allStripePrice {
        edges {
          next {
            active
            product {
              id
              name
              images
              default_price
            }
            currency
            id
          }
        }
      }
    }
    `
  )

  const getProducts = (
    data.allStripePrice.edges.map((option, index) => {
      const item = option.next;

      // console.log('item', item);
    
      if(item && item.active) {
        return (<li className="list-item" onClick={onOptionClicked(item.product, 'value')} key={index}>
          {item.product.name}
        </li>)
      }
    })
  )

  return (
    <ProductListStyles>
      {valueDropdownOpen && (
        <div className="dropdown-list-container">
          <ul className="dropdown-list" ref={node}>
            {getProducts}
          </ul>
        </div>
      )}
    </ProductListStyles>
  )
}

export default ProductList