// Import Core
import React from "react";
// import { useState, useRef } from "react";
import styled from 'styled-components';
import { navigate } from 'gatsby';
// Import Libraries
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// Import Components
import SectionTitle from "../SectionTitle";
// Import Utils
import { getCurrentDate } from '../../../utils/getCurrentDate';

/* Styled Components */
const NewsletterFormStyles = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 992px;
    @media (max-width: 992px) {
      padding: 40px 64px 0;
      width: calc(100% - 128px);
    }
    @media (max-width: 768px) {
      width: fit-content;
    }
    @media (max-width: 575px) {
      padding: 40px 36px 20px;
      width: calc(100% - 72px);
    }
    form {
      width: 100%;
      padding: 64px 104px 0;
      @media (max-width: 992px) {
        padding: 24px 16px;
      }
      @media (max-width: 575px) {
        padding: 0;
      }
      >* {
        width: 100%;
        margin: 12px 0;
      }
      .two-col {
        width: 100%;
        display: flex;
        gap: 36px;
        label {
          width: 60%;
          @media (max-width: 575px) {
            width: 100%;
          }
        }
        .btn {
          display: flex;
          width: 40%;
          align-self: flex-end;
          @media (max-width: 575px) {
            align-self: flex-start;
            width: 100%;
          }
          button {
            padding: 13px 16px 12px;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;
            color: var(--white);
            background: var(--primary-500);
            &:hover {
              cursor: pointer;
              background: var(--primary-400);
            }
            @media (max-width: 575px) {
              width: 100%;
            }
          }
        }

        @media (max-width: 575px) {
          flex-direction: column;
          gap: 16px;
          >* {
            width: 100%;
          }
        }
      }
      input {
        background-color: var(--white);
        width: calc(100% - 24px);
        &:focus {
          outline: none;
        }
      }
      .consent {
        display: flex;
        input {
          width: auto;
          margin-right: 10px;
        }
      }
      .error-msg {
        color: var(--error-500);
        font-size: 0.8rem;
      }
    }
  }`;

const NewsletterForm = ({ location }) => {
    // Initiate forms
    const { register, handleSubmit, formState: { errors} } = useForm();

  // const domain = location.origin;

    // Handle form submit
    const handlePost = async (formData) => {
      // // SEND TO REVINATE
      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");

      // var raw = JSON.stringify({
      //   "tokens": [
      //     "a6a289fa-d361-4ced-b11d-9f05048d1601"
      //   ],
      //   "contacts": [
      //     {
      //       "email": "christine@umidigital.co.uk"
      //     }
      //   ]
      // });

      // var requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: 'follow'
      // };

      // fetch("http://contact-api.inguest.com/api/add-contacts-to-lists", requestOptions)
      //   .then(response => response.text())
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error));


      // Create unique form ID
      const uuid = uuidv4();
      formData.uuid = uuid;
  
      // Set current date value
      formData.date = getCurrentDate();
  
      // Send data to sanity
      const api = 'https://1gqu4qfy3g.execute-api.eu-west-2.amazonaws.com/staging/';
      axios
        .post(api, { formType: 'new-form', body: formData })
        .then((response) => {
          console.log('response', response);
          // setLoading(false);
          navigate('/form-confirmation/?type=newsletter&status=success');
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
          navigate('/form-confirmation/?type=newsletter&status=fail');
        });
    }

  return (
    <NewsletterFormStyles>
      <SectionTitle subtitle='Newsletter' title='Join The GHG' content='Get our free monthly newsletter, latest special offers, and updates. Join more than 14,000 other members.' className='section-title' />
        <div>
          <form
          onSubmit={handleSubmit(handlePost)}
          name="newsletter-form"
          method="POST"
          honeypot="got-ya"
          id="newsletter-form"
          >

          <input type="hidden" name="form-name" value="newsletter-form" />
          <input
              type="hidden"
              name="formId"
              value="newsletter-form"
              {...register('formId')}
            />

            <div className="two-col">
              <label htmlFor="email">
                <p>Email address</p>
                {errors && errors.email && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="email" placeholder="your@email.com" {...register('email', ({
                  required: true,
                  pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                }))} />
              </label>

              <div className="btn large">
                <button type="submit" >COUNT ME IN</button>
              </div>

            </div>

            <label className="consent" htmlFor="consent">
              {errors && errors.consent && <p className="error-msg" role="alert">Please accept the privacy policy. </p>}
              <input type="checkbox" name="consent" {...register('consent', ({
                  required: true,
                }))} />
              <p>I accept the GHG's privacy policy. 
                {/* <a href={domain + '/privacy-policy'}>Details here.</a> */}
                {/* TODO: FIX LINK */}
                <a href={'/privacy-policy'}>Details here.</a>
              </p>
            </label>

            {/* HIDDEN FIELDS */}
            <input type="hidden" name="date" value=""{...register('date')}  />
            <input type="hidden" name="uuid" value=""{...register('uuid')}  />

            {/* HONEYPOT */}
            <label
              htmlFor="got-ya"
              style={{
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: "1px",
                width: "1px",
                margin: "-1px",
                padding: "0",
                border: "0",
              }}
            >
              Don’t fill this out if you're human:
              <input tabIndex="-1" name="got-ya" {...register('got-ya')} />
            </label>

          </form>
        </div>
    </NewsletterFormStyles>
  )
}

export default NewsletterForm