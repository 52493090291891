import React, { createContext, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

// Save all global objects as context so the graphql call is made once but data can be used across the site /pages / components
const RootContext = createContext({
  allHotels: [],
  allPillarPages: [],
  // allPlaceholderImages: [],
  allBlogPosts: [],
  allSpecialOffers: [],
  // allSiteSettings: []
});

const RootProvider = ({ children }) => {
  // Get all data we will need to use globally
  const globalData = useStaticQuery(graphql`
    query GlobalQuery {
      # # Only need images from paid hotels
      # allSanityHotelImages: allSanityHotel(filter: {displayOptions: {paid: {eq: true}}}) {
      # 	nodes {
      # 		# ...HotelFragment
      # 		_id
      # 		reviewTextAndMedia {
      # 			images {
      # 				asset {
      # 					gatsbyImageData(
      # 						width: 800,
      # 						placeholder: BLURRED
      # 					)
      # 				}
      # 			}
      # 		}
      # 	}
      # }
      allSanityHotels: allSanityHotel(
        # filter: {displayOptions: {omitted: {eq: false}}}
        filter: {
          _id: {
            nin: [
              "12edc423-f8c2-4a86-82f7-f757dad6a23c"
              "3a68f1ac-c963-441c-a96d-a5e506ee62ee"
            ]
          }
          displayOptions: { omitted: { eq: false }, extended: { eq: false } }
          name: { ne: "" }
        }
      ) {
        nodes {
          # ...HotelFragment
          _id
          _type
          name
          hotelParent: parentPage
          slug {
            current
          }
          displayOptions {
            paid
            inGuide
            shortlist
            extended
            omitted
          }
          addressGroup {
            street
            town
            village
            postCode
            region
            county
            country
          }
          location {
            lat
            lng
          }
          reviewTextAndMedia {
            # images {
            # 	asset {
            # 		gatsbyImageData(
            # 			width: 800,
            # 			placeholder: BLURRED
            # 		)
            # 	}
            # }
            homepageFeaturedImage {
              asset {
                gatsbyImageData(width: 800, placeholder: BLURRED)
              }
            }
            summaryContent: _rawReviewSummary(
              resolveReferences: { maxDepth: 5 }
            )
          }
          contactGroup {
            telephone
            displayEmail
            contactEmailAddress
            bookingUrl
            bookNowUrl
            websiteUrl
          }
          hotelInformation {
            searchTags {
              label
              _key
              value
            }
          }
          searchTagSummaries {
            searchTagSummaries {
              tag {
                value
              }
              summary
            }
          }
        }
      }
      allSanityPillarPages: allSanityPillar {
        nodes {
          # ...PillarFragment
          _id
          _type
          name
          slug {
            current
          }
          parentPage {
            _id
            slug {
              current
            }
          }
          pillarHero {
            heroImage {
              asset {
                gatsbyImageData(width: 600, placeholder: BLURRED)
              }
            }
          }
          pillarPageOptions {
            pageType
            showOnHomepage
          }

          # Pillar filter criteria
          pillarFilterOptions
          hotelSearchOptions {
            filterByCountry
            filterByCounty
            filterByTown
			filterBySpecialOffers
          }
          # By Search Tags / Words
          hotelSearchTags {
            filterByTags {
              label
              value
            }
          }

          # By Coordinates
          mapSearchOptions {
            filterByLocation {
              lat
              lng
            }
            mapSearchRadius
          }
        }
      }
      # allSanityPlaceholderImages: sanityPlaceholderImages {
      # 	generalPlaceholder {
      # 		asset {
      # 			gatsbyImageData(
      # 				width: 1600,
      # 				placeholder: BLURRED
      # 			)
      # 		}
      # 	}
      # 	blogPlaceholder {
      # 		asset {
      # 			gatsbyImageData(
      # 				width: 1600,
      # 				placeholder: BLURRED
      # 			)
      # 		}
      # 	}
      # 	attractionsPlaceholder {
      # 		asset {
      # 			gatsbyImageData(
      # 				width: 800,
      # 				placeholder: BLURRED
      # 			)
      # 		}
      # 	}
      # 	specialOffersPlaceholder {
      # 		asset {
      # 			gatsbyImageData(
      # 				width: 800,
      # 				placeholder: BLURRED
      # 			)
      # 		}
      # 	}
      # }
      allSanityBlogPosts: allSanityBlog {
        nodes {
          ...BlogFragment
        }
      }
      allSanitySpecialOffers: allSanitySpecialOffer(
        filter: { enabled: { eq: true } }
      ) {
        nodes {
          ...SpecialOfferFragment
        }
      }
      # allSanitySiteSettings: sanitySiteSettings {
      # 	id

      # 	blogArchive {
      # 		_id
      # 	}
      # 	specialOffersArchive {
      # 		_id
      # 	}
      # Menus
      # mainNav {
      # 	title
      # 	items {
      # 		name
      # 		navigationItemUrl {
      # 			displayExternal
      # 			externalUrl
      # 			internalLink {
      # 				... on SanityBlog {
      # 					_id
      # 					_type
      # 					name
      # 					slug {
      # 						current
      # 					}
      # 				}
      # 				... on SanityHotel {
      # 					_id
      # 					_type
      # 					name
      # 					hotelParent: parentPage
      # 					slug {
      # 						current
      # 					}
      # 				}
      # 				... on SanityPillar {
      # 					_id
      # 					_type
      # 					name
      # 					slug {
      # 						current
      # 					}
      # 					parentPage {
      # 						slug {
      # 							current
      # 						}
      # 					}
      # 				}
      # 			}
      # 		}
      # 	}
      # }

      # hamburgerMenu {
      # 	title
      # 	displayList
      # 	items {
      # 		name
      # 		navigationItemUrl {
      # 			displayExternal
      # 			externalUrl
      # 			internalLink {
      # 				... on SanityBlog {
      # 					_id
      # 					_type
      # 					name
      # 					slug {
      # 						current
      # 					}
      # 				}
      # 				... on SanityHotel {
      # 					_id
      # 					_type
      # 					name
      # 					hotelParent: parentPage
      # 					slug {
      # 						current
      # 					}
      # 				}
      # 				... on SanityPillar {
      # 					_id
      # 					_type
      # 					name
      # 					slug {
      # 						current
      # 					}
      # 					parentPage {
      # 						slug {
      # 							current
      # 						}
      # 					}
      # 				}
      # 			}
      # 		}
      # 	}
      # 	itemsList {
      # 		name
      # 		items {
      # 			name
      # 			navigationItemUrl {
      # 				displayExternal
      # 				externalUrl
      # 				internalLink {
      # 					... on SanityBlog {
      # 						_id
      # 						_type
      # 						name
      # 						slug {
      # 							current
      # 						}
      # 					}
      # 					... on SanityHotel {
      # 						_id
      # 						_type
      # 						name
      # 						hotelParent: parentPage
      # 						slug {
      # 							current
      # 						}
      # 					}
      # 					... on SanityPillar {
      # 						_id
      # 						_type
      # 						name
      # 						slug {
      # 							current
      # 						}
      # 						parentPage {
      # 							slug {
      # 								current
      # 							}
      # 						}
      # 					}
      # 				}
      # 			}
      # 		}
      # 	}
      # }

      # socialLinks {
      #   socialIcon {
      #     name
      #     provider
      #   }
      #   navItem {
      #     name
      #     navigationItemUrl {
      #       displayExternal
      #       externalUrl
      #       internalLink {
      #         ... on SanityBlog {
      #           id
      #           name
      #           slug {
      #             current
      #           }
      #         }
      #         ... on SanityHotel {
      #           id
      #           name
      #           slug {
      #             current
      #           }
      #         }
      #       }
      #     }
      #   }
      # }
      # aboutGoodHotelGuideExperts
      # shortlistedHotelInfo
      # dropdownButtonTitle
      # aboutGoodHotelGuide {
      # 	aboutGoodHotelGuideDescription
      # 	navItem {
      # 		name
      # 		navigationItemUrl {
      # 			displayExternal
      # 			externalUrl
      # 			internalLink {
      # 				... on SanityBlog {
      # 					_id
      # 					_type
      # 					name
      # 					slug {
      # 						current
      # 					}
      # 				}
      # 				... on SanityHotel {
      # 					_id
      # 					_type
      # 					name
      # 					hotelParent: parentPage
      # 					slug {
      # 						current
      # 					}
      # 				}
      # 				... on SanityPillar {
      # 					_id
      # 					_type
      # 					name
      # 					slug {
      # 						current
      # 					}
      # 					parentPage {
      # 						slug {
      # 							current
      # 						}
      # 					}
      # 				}
      # 			}
      # 		}
      # 		addForm
      # 		form {
      # 			_type
      # 			formOptions
      # 		}
      # 	}
      # 	subheading
      # 	title
      # 	featureList {
      # 		_key
      # 		featureIcon {
      # 			name
      # 		}
      # 		featureName
      # 		featureDescription
      # 	}
      # }
      # inGuideNotPaidContent:_rawInGuideNotPaidContent(resolveReferences: {maxDepth: 5})
      # hotelCtaText
      # }
    }
  `);

  // Filter out hotels that aren't paid, inGuide, shortlisted or extended
  const relevantHotels = globalData.allSanityHotels.nodes.filter((hotel) => {
    if (
      hotel.displayOptions.paid ||
      hotel.displayOptions.inGuide ||
      hotel.displayOptions.shortlisted ||
      hotel.displayOptions.extended
    ) {
      return hotel;
    }
  });
  const [allHotels, setAllHotels] = useState(relevantHotels);
  const [allPillarPages, setAllPillarPages] = useState(
    globalData.allSanityPillarPages.nodes
  );
  // const [allPlaceholderImages, setAllPlaceholderImages] = useState(globalData.allSanityPlaceholderImages);
  const [allBlogPosts, setAllBlogPosts] = useState(
    globalData.allSanityBlogPosts.nodes
  );
  const [allSpecialOffers, setAllSpecialOffers] = useState(
    globalData.allSanitySpecialOffers.nodes
  );
  // const [allSiteSettings, setAllSiteSettings] = useState(globalData.allSanitySiteSettings);

  return (
    <>
      <RootContext.Provider
        value={{
          allHotels,
          allPillarPages,
          // allPlaceholderImages,
          allBlogPosts,
          allSpecialOffers,
          // allSiteSettings
        }}
      >
        {children}
      </RootContext.Provider>
    </>
  );
};

export default RootContext;
export { RootProvider };
