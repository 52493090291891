const transformToUpperCase = (str, separators) => {
	separators = separators || [' '];
	var regex = new RegExp('(^|[' + separators.join('') + '])(\\w)', 'g');

	// console.log(str, str.includes(separators))
  // If the string contains '-' then replace so text is Title Case after '-', else just normal title case
	if (str?.includes(separators)) {
		return str.toLowerCase().replace(regex, function (x) {
			return x.toUpperCase();
		});
	} else {
		return str?.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}
};
export default transformToUpperCase;
