// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Libraries
import { PortableText } from '@portabletext/react';
// Import Components
import PortableTextComponent from './PortableTextComponent';

/* Styled Components */
const SectionTitleStyles = styled.div`
	color: var(--neutral-900);

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	padding: 64px 120px 0;
	gap: 40px;
	@media (max-width: 992px) {
		padding: 40px 64px 0;
		gap: 32px;
	}
	@media (max-width: 768px) {
		padding: 40px 24px 20px;
		align-items: center;
		gap: 8px;
	}

	.content {
		@media (max-width: 768px) {
			width: 100%;
			text-align: center;
		}
		/* .subheading {
			margin-bottom: 24px;
			@media (max-width: 768px) {
				margin-bottom: 16px;
			}
		}
		.title {
			margin-bottom: 24px;
			@media (max-width: 768px) {
				margin-bottom: 16px;
			}
		} */
	}

	&.two-col {
		color: var(--neutral-900);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 64px 120px 0;
		gap: 40px;
		@media (max-width: 992px) {
			padding: 40px 64px 0;
			gap: 32px;
		}
		@media (max-width: 768px) {
			padding: 40px 24px 20px;
			flex-direction: column;
			align-items: center;
			gap: 8px;
		}

		.content {
			width: 65%;
			display: flex;
			flex-direction: column;
			/* justify-content: center;
			align-items: center; */
			gap: 16px;

			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}

	/* &.left-aligned {
		color: var(--neutral-900);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 64px 120px 0;
		gap: 40px;
		@media (max-width: 992px) {
			padding: 40px 64px 0;
			gap: 32px;
		}
		@media (max-width: 768px) {
			padding: 40px 24px 20px;
			flex-direction: column;
			align-items: center;
			gap: 8px;
		}
	} */

	/* Subtitle to Title Case */
	&.subtitle-to-title-case {
		.subheading {
			text-transform: capitalize;
			font-size: var(--h6-font-size);
			font-weight: normal;
			@media (max-width: 768px) {
			font-size: var(--h6-mobile-font-size);
			}
		}
	}
	@media (max-width: 768px) {
		.content {
			width: 100%;
		}
		.btn-holder {
			/* margin-top: 36px; */
			width: 100%;
			button {
				width: 100%;
			}
			/* @media (max-width: 768px) {
				&.map-btn {
					display: none;
				}
			} */

			/* margin-top: 24px; */
			@media (max-width: 768px) {
				margin-top: 16px;
			}
		}
	}
`;

/* Render Component */
const SectionTitle = ({
	twoCol,
	subtitle,
	title,
	content,
	button,
	buttonTwo,
	className,
}) => {
	// if twoCol prop has been passed down then add 'two-col' classname - if no button but twoCol then text is aligned left
	let additonalClasses = '';
	// if(twoCol && button) {
	// 	additonalClasses = ' two-col';
	// } else if(twoCol) {
	// 	additonalClasses = ' left-aligned';
	// }
	if(twoCol) {
		additonalClasses = ' two-col';
	}
	
	return (
		<SectionTitleStyles className={`${className}${additonalClasses}`}>
			<div className='content'>
				{subtitle && <h5 className='subheading'>{subtitle}</h5>}
				<h2 className='title'>{title}</h2>
				{content && (content.text || content.pillarText || content.sectionText) &&
					(<div className="section-title-content">
						<PortableText
							value={content.text || content.pillarText || content.sectionText} 
							components={PortableTextComponent} 
						/>
					</div>)
				|| <p>{content}</p>}
			</div>
			{button && <div className={button.props.className === 'slide-in-map-button' ? 'btn-holder map-btn' : 'btn-holder'}>{button}{buttonTwo && buttonTwo}</div>}
		</SectionTitleStyles>
	);
};

export default SectionTitle;
