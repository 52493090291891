import React from 'react';
import { FaBeer, FaCocktail, FaBath, FaCoffee, FaWheelchair, FaToilet, FaBlind, FaSnowflake, FaGlassWhiskey, FaUtensils, FaLeaf, FaGlassCheers, FaWifi, FaBuilding, FaRing, FaHandshake, FaUmbrellaBeach, FaHiking, FaSwimmer, FaSkating, FaDumbbell, FaGolfBall, FaTv, FaWineGlassAlt, FaCouch, FaParking, FaConciergeBell, FaClock, FaBroom, FaSmoking, FaLock, FaFireExtinguisher, FaEye, FaSpa, FaBiking, FaHorse, FaFish, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaBed, FaMusic, FaBaby, FaDog, FaCreditCard, FaDollarSign, FaNewspaper, FaCheck, FaAngleRight, FaInfoCircle, FaTwitter, FaFacebook, FaInstagram, FaLinkedin, FaThumbsUp, FaClipboardCheck, FaCrown } from 'react-icons/fa';

export const renderFontAwesomeIcon = element => { 
  let icon = <FaCheck />;
  switch(element) {
    case 'FaBeer':
      icon = <FaBeer />
    break;
    case 'FaCocktail':
      icon = <FaCocktail />
    break;
    case 'FaBath':
      icon = <FaBath />
    break;
    case 'FaCoffee':
      icon = <FaCoffee />
    break;
    case 'FaWheelchair':
      icon = <FaWheelchair />
    break;
    case 'FaToilet':
      icon = <FaToilet />
    break; 
  case 'FaBlind':
      icon = <FaBlind />
    break; 
  case 'FaSnowflake':
      icon = <FaSnowflake />
    break; 
  case 'FaGlassWhiskey':
      icon = <FaGlassWhiskey />
    break; 
  case 'FaUtensils':
      icon = <FaUtensils />
    break;
  case 'FaLeaf':
      icon = <FaLeaf />
    break;
  case 'FaGlassCheers':
      icon = <FaGlassCheers />
    break;
  case 'FaWifi':
      icon = <FaWifi />
    break;
  case 'FaBuilding':
      icon = <FaBuilding />
    break;
  case 'FaRing':
      icon = <FaRing />
    break;
  case 'FaHandshake':
      icon = <FaHandshake />
    break;
  case 'FaUmbrellaBeach':
      icon = <FaUmbrellaBeach />
    break;
  case 'FaHiking':
      icon = <FaHiking />
    break;
  case 'FaSwimmer':
      icon = <FaSwimmer />
    break;
  case 'FaSkating':
      icon = <FaSkating />
    break;
  case 'FaDumbbell':
      icon = <FaDumbbell />
    break;
  case 'FaGolfBall':
      icon = <FaGolfBall />
    break;
  case 'FaTv':
      icon = <FaTv />
    break;
  case 'FaWineGlassAlt':
      icon = <FaWineGlassAlt />
    break;
  case 'FaCouch':
      icon = <FaCouch />
    break;
  case 'FaParking':
      icon = <FaParking />
    break;
  case 'FaConciergeBell':
      icon = <FaConciergeBell />
    break;
  case 'FaClock':
      icon = <FaClock />
    break;
  case 'FaBroom':
      icon = <FaBroom />
    break;
  case 'FaSmoking':
      icon = <FaSmoking />
    break;
  case 'FaLock':
      icon = <FaLock />
    break;
  case 'FaFireExtinguisher':
      icon = <FaFireExtinguisher />
    break;
  case 'FaEye':
      icon = <FaEye />
    break;
  // case 'FaSauna':
  //     icon = <FaSauna />
  //   break;
  case 'FaSpa':
      icon = <FaSpa />
    break;
  case 'FaBiking':
      icon = <FaBiking />
    break;
  case 'FaHorse':
      icon = <FaHorse />
    break;
  case 'FaFish':
      icon = <FaFish />
    break;
  case 'FaMapMarkerAlt':
      icon = <FaMapMarkerAlt />
    break;
  case 'FaEnvelope':
      icon = <FaEnvelope />
    break;
  case 'FaPhoneAlt':
      icon = <FaPhoneAlt />
    break;
  case 'FaBed':
      icon = <FaBed />
    break;
  case 'FaMusic':
      icon = <FaMusic />
    break;
  case 'FaBaby':
      icon = <FaBaby />
    break;
  case 'FaDog':
      icon = <FaDog />
    break;
  case 'FaCreditCard':
      icon = <FaCreditCard />
    break;
  case 'FaDollarSign':
      icon = <FaDollarSign />
    break;
  case 'FaNewspaper':
      icon = <FaNewspaper />
    break;
  case 'FaCheck':
      icon = <FaCheck />
    break;
  case 'FaAngleRight':
      icon = <FaAngleRight />
    break;
    case 'FaInfoCircle':
      icon = <FaInfoCircle />
    break;
    case 'FaThumbsUp':
      icon = <FaThumbsUp />
    break;
    case 'FaClipboardCheck':
      icon = <FaClipboardCheck />
    break;
    case 'FaCrown':
      icon = <FaCrown />
    break;
    case 'FaTwitter':
      icon = <FaTwitter />
    break;
    case 'FaFacebookF':
      icon = <FaFacebook />
    break;
    case 'FaLinkedinIn':
      icon = <FaLinkedin />
    break;
    case 'FaInstagram':
      icon = <FaInstagram />
    break;

    default:
      icon = <FaCheck />
    break;
  }
  return icon;
}