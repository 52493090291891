// Import Core
import React, { useState, useRef, Suspense, lazy } from "react";
import { navigate } from 'gatsby';
import styled from 'styled-components';
// Import Libraries
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// Import Components
import SectionTitle from "../SectionTitle";
import Dropdown from '../Dropdown';
import Loader from '../../blocks/Loader';
// Import Utils
import { getCurrentDate } from '../../../utils/getCurrentDate';
import useDropdown from '../../../utils/useDropdown';
// Import Assets
import phoneCodes from '../../../utils/phoneCodes'
// Import Icons
import { FaChevronDown } from 'react-icons/fa';
// Lazy Load Packages until needed
const DatePicker = lazy(() => import(/*webpackChunkName: "react-datepicker" */ "react-datepicker"));

/* Styled Components */
const SubmitReviewFormStyles = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 992px;
    max-width: 100%;
    @media (max-width: 992px) {
      padding: 40px 64px 0;
      width: calc(100% - 128px);
    }
    @media (max-width: 768px) {
      padding: 40px 36px 20px;
      width: calc(100% - 72px);
      /* width: fit-content; */
    }
    @media (max-width: 575px) {
      padding: 40px 36px 20px;
      width: calc(100% - 72px);
    }
    form {
      width: calc(100% - 208px);
      padding: 64px 104px;
      @media (max-width: 992px) {
        /* padding: 24px; */
        /* width: calc(100% - 48px); */
        padding: 0;
        width: 100%;
      }
      @media (max-width: 575px) {
        padding: 0;
        width: 100%;
      }
      >* {
        width: 100%;
      }
      .stay-details, .user-details, .checkboxes {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;
      }
      .two-col {
        width: 100%;
        display: flex;
        gap: 36px;
        label {
          width: 60%;
          @media (max-width: 575px) {
            width: 100%;
          }
        }

        @media (max-width: 575px) {
          flex-direction: column;
          gap: 16px;
          >* {
            width: 100%;
          }
        }
      }
      input {
        background-color: var(--white);
        width: calc(100% - 24px);
        &:focus {
          outline: none;
        }
      }
      textarea {
        width: 764px;
        padding: 10px;
        height: 101px;
        outline: none;
        background: transparent;
        max-width: calc(100% - 22px);
      }
      .dropdown-container, .dropdown-container {
        width: 100%;
        text-transform: none;
        line-height: 128%;
        .dropdown-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--neutral-300);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        padding: 10px;
        position: relative;
        cursor: pointer;
          &.selected {
            color: var(--body-color);
          }
        }
        svg {
            font-size: 12px;
          }
        .dropdown-list-container {
          width: 100%;
          position: relative;
          .dropdown-list {
            padding: 0;
            margin: 0;
            border: 1px solid var(--neutral-300);
            position: absolute;
            background-color: var(--white);
            width: inherit;
            max-height: 240px;
            overflow-y: scroll;
            z-index: 2;
            .list-item {
              list-style: none;
              padding: 0.2em 10px;
              cursor: pointer;
              &:hover {
                background-color: var(--neutral-50);
              }
            }
          }
        }
      }
      .dropdown {
        width: calc(100% - 32px);
        max-width: unset;
      }
      .checkbox {
        display: flex;
        input {
          width: auto;
          margin-right: 10px;
        }
      }
      .error-msg {
        color: var(--error-500);
        font-size: 0.8rem;
      }
      .btn {
          display: flex;
          width: 40%;
          align-self: flex-end;
          @media (max-width: 575px) {
            align-self: flex-start;
            width: 100%;
          }
          button {
            padding: 13px 16px 12px;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;
            color: var(--white);
            background: var(--primary-500);
            &:hover {
              cursor: pointer;
              background: var(--primary-400);
            }
            @media (max-width: 575px) {
              width: 100%;
            }
          }
        }
    }
  }`;

  // TODO: change all dropdowns to use component & remove current dropdowns & styling

const SubmitReviewForm = () => {
  // Initiate forms
  const { register, handleSubmit, formState: { errors} } = useForm();

  // Date picker usestate
  const [startDate, setStartDate] = useState(new Date());

    // Create useStates for the custom dropdowns
  const [stayLength, setStayLength] = useState("");
  const [stayLengthDropdownOpen, setStayLengthDropdownOpen] = useState(false);

  const [isCountryDropdownOpen, toggleCountryDropdown, handleCountryDropdownClick, selectedCountryItem, setSelectedCountryItem] = useDropdown();

  // Create length of stay array (1 - 14 nights)
  const stayArray = [...Array(14).keys()].map(x => ++x)

  // Toggle open/close of dropdown lists
  const toggle = () => () => {
    setStayLengthDropdownOpen(!stayLengthDropdownOpen);
  }

  // Updates useState values when dropdown option clicked
  const onOptionClicked = (option) => () => {
    setStayLength(option);
    // Close dropdowns
    setStayLengthDropdownOpen(false);
  };

  const countryArray = phoneCodes.map(item => item.name)
  // console.log(countryArray);

  // Used to check for clicks outside of dropdowns
  const node = useRef();

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Handle form submit
  const handlePost = async (formData) => {
    // Create unique submission form ID
    const uuid = uuidv4();

    // Set remaining formData fields
    formData.visitdate = formatDate(startDate);
    formData.staylength = stayLength;
    formData.uuid = uuid;
    formData.date = getCurrentDate();
    formData.country = selectedCountryItem;

    // Send data to sanity
    const api = 'https://1gqu4qfy3g.execute-api.eu-west-2.amazonaws.com/staging/';
    axios
      .post(api, { formType: 'new-form', body: formData })
      .then((response) => {
        console.log('response', response);
        navigate('/form-confirmation/?type=submitreview&status=success');
      })
      .catch((error) => {
        console.log(error);
        navigate('/form-confirmation/?type=submitreview&status=fail');
      });
  }

  return (
    <SubmitReviewFormStyles>
      <SectionTitle subtitle='' title='Submit a Review' className='section-title' />
        <div>
          <form
          onSubmit={handleSubmit(handlePost)}
          name="submitreview-form"
          method="POST"
          honeypot="got-ya"
          id="submit-review-form"
          >

          <input type="hidden" name="form-name" value="submitreview-form" />
          <input
              type="hidden"
              name="formId"
              value="submitreview-form"
              {...register('formId')}
            />

            <div className="stay-details">
              <label htmlFor="hotelname">
                <p>Name of hotel*</p>
                {errors && errors.hotelname && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="hotelname" {...register('hotelname', ({ required: true }))} />
              </label>
              <label htmlFor="town">
                <p>Town*</p>
                {errors && errors.town && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="town" {...register('town', ({ required: true }))} />
              </label>
              <label htmlFor="visitdate">
                <p>Date of visit*</p>
                {errors && errors.visitdate && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="visitdate" type="hidden" {...register('visitdate')} />
                <Suspense fallback={<Loader />} key={startDate}>
                  <DatePicker selected={startDate} value={formatDate(startDate)} onChange={(date) => setStartDate(date)} />
                </Suspense>
              </label>
              <label htmlFor="staylength">
                <p>How many nights*</p>
                {/* Register the input type */}
                <input type="hidden" name="staylength" value={stayLength} {...register('staylength')}/>
                {/* Render custom select dropdown */}
                <div className="dropdown-container staylength">
                  <div className={`dropdown-header container ${stayLength ? 'selected' : null}`} onClick={toggle()}>{stayLength ? stayLength : "--Choose--"}
                  <FaChevronDown />
                  </div>
                  {stayLengthDropdownOpen && (
                    <div className="dropdown-list-container">
                      <ul className="dropdown-list" ref={node}>
                        {stayArray.map((option, index) => (
                          <li className="list-item" onClick={onOptionClicked(option)} key={index}>
                            {option}
                          </li>))}
                      </ul>
                    </div>
                  )}
                </div>
              </label>
              <label htmlFor="comments">
                <p>Your comments*</p>
                {errors && errors.comments && <p className="error-msg" role="alert">Please complete this field</p>}
                {/* <input hidden type="text" {...register('comments', ({ required: true }))} /> */}
                <textarea name="comments" {...register('comments', ({ required: true }))} />
              </label>
            </div>

            <div className="user-details">
              <label htmlFor="name">
                <p>Name*</p>
                {errors && errors.name && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="name" {...register('name', ({ required: true }))} />
              </label>
              <label className="anonymity checkbox" htmlFor="anonymity">
                <input type="checkbox" name="anonymity" {...register('anonymity')} />
                <p>Do not publish my name</p>
              </label>
              <label htmlFor="email">
                <p>Email address*</p>
                {errors && errors.email && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="email" placeholder="your@email.com" {...register('email', ({
                  required: true,
                  pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                }))} />
              </label>

              <label htmlFor="postcode">
                <p>Post code*</p>
                  {errors && errors.postcode && <p className="error-msg" role="alert">Please complete this field</p>}
                  <input name="postcode" {...register('postcode', ({ required: true }))} />
              </label>      

              {/* Register the input type */}
              <input type="hidden" name="country" {...register('country')}/>
              <Dropdown
                key='country'
                label='Country' 
                data={countryArray}
                toggleDropdown={toggleCountryDropdown}
                handleItemClick={handleCountryDropdownClick}
                isOpen={isCountryDropdownOpen}
                selectedItem={selectedCountryItem}
                showAllOption={false}
              />
  
            </div>
            
            <div className="checkboxes">
              <label className="newsletter checkbox" htmlFor="newsletter">
                <input type="checkbox" name="newsletter" {...register('newsletter')} />
                <p>I wish to receive the GHG newsletter, special offers and updates</p>
              </label>
              <label className="consent checkbox" htmlFor="consent">
                {errors && errors.consent && <p className="error-msg" role="alert">Please accept the privacy policy. </p>}
                <input 
                  type="checkbox" 
                  name="consent" {...register('consent', ({
                    required: true,
                  }))} 
                />
                <p>I accept the GHG's <a href={'/privacy-policy'}>privacy policy</a> and agree to the terms and conditions (see below)*
                </p>
              </label>
            </div>

            {/* HIDDEN FIELDS */}
            <input type="hidden" name="date" value=""{...register('date')}  />
            <input type="hidden" name="uuid" value=""{...register('uuid')}  />

            {/* HONEYPOT */}
            <label
              htmlFor="got-ya"
              style={{
                position: "absolute",
                overflow: "hidden",
                clip: "rect(0 0 0 0)",
                height: "1px",
                width: "1px",
                margin: "-1px",
                padding: "0",
                border: "0",
              }}
            >
              Don’t fill this out if you're human:
              <input tabIndex="-1" name="got-ya" {...register('got-ya')} />
            </label>

            <div className="btn large">
              <button type="submit" >SUBMIT REVIEW</button>
            </div>
          </form>
        </div>
    </SubmitReviewFormStyles>
  )
}

export default SubmitReviewForm