// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Icons
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

/* Styled Components */
const DropdownStyles = styled.div`
  flex: 1;
  .dropdown {
    background-color: var(--white);
    border: 1px solid var(--neutral-300);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 1rem;
    line-height: 131%;
    color: var(--neutral-500);
    text-decoration: none;
    padding: 12px 16px;
    max-width: 376px;
    flex: 1;
    @media (max-width: 768px) {
      max-width: unset;
    }
    svg {
      font-size: 12px;
    }
    .dropdown-header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
  }
  .dropdown-body {
    position: absolute;
    margin-top: 5px;
    max-width: 376px;
    max-height: 200px;
    height: fit-content;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    border-top: 1px solid var(--neutral-300);
    background: var(--white);
    max-width: 376px;
    width: 100%;
    display: none;
    &.open {
      display: block;
    }
  }
  .dropdown-item {
    gap: 8px;
    max-width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    color: var(--neutral-900);
    text-decoration: none;
    padding: 8px 16px;
    line-height: 130%;
    &:hover {
      cursor: pointer;
    }
    .dropdown-item-dot {
      opacity: 0;
      transition: all .2s ease-in-out;
      &.selected {
        color: var(--primary);
        opacity: 1;
      }
    }
    &:hover {
      color: var(--white);
      background: var(--primary-500);
      .dropdown-item-dot {
        &.selected {
          color: var(--white);
        }
      }
    }
  }
`;

/* Render Component */
const Dropdown = ({label, data, selectedItem, toggleDropdown, handleItemClick, isOpen, showAllOption}) => {
  
  // const [isOpen, setOpen] = useState(false);
  // const [items, setItems] = useState(data);
  const items = data;
  
  // const toggleDropdown = () => setOpen(!isOpen);
  
  // const handleItemClick = (id) => {
  //   console.log(id)
  //   selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
  //   setOpen(!isOpen);
  // }
  // console.log('category', {label, selectedItem})
  return (
    <DropdownStyles>
      {label && <label className='large'>{label}</label>}
      <div className="dropdown">
        <div className='dropdown-header' onClick={toggleDropdown}>
        {selectedItem ? items.find(item => item == selectedItem) : "Choose..."}
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
        <div className={`dropdown-body ${isOpen && 'open'}`}>
          {showAllOption &&
            <div className="dropdown-item" onClick={(e) => handleItemClick(null)}><span className='dropdown-item-dot'>• </span>All</div>
          }

          {items.map((item, index) => (
            <div key={`${item}-${index}`} className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item}>
              <span className={`dropdown-item-dot ${item == selectedItem && 'selected'}`}>• </span>
              {item}
            </div>
          ))}
        </div>
    </DropdownStyles>
  )
}

export default Dropdown;