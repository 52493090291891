// Import Core
import React, { useContext } from "react";
import styled from "styled-components";
// Import Components
import FeatureList from "./FeatureList";
// Import Utils
import { GetLinks } from "../../utils/getNavItemLinks";

/* Styled Components */
const AboutGuideStyles = styled.section`
  /* padding: 64px 120px 0; */
  padding: 64px 120px 32px;
  gap: 40px;
  background: var(--primary-500);
  color: var(--white);
  @media (max-width: 992px) {
    /* padding: 40px 64px 0; */
    padding: 40px 64px 20px;
    gap: 32px;
    color: var(--white);
  }
  @media (max-width: 768px) {
    padding: 40px 24px 0;
  }

  .content {
    display: flex;
    gap: 40px;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .section-title {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
      .subheading {
        margin-bottom: 16px;
      }
      .title {
        margin-bottom: 36px;
      }
      a {
        text-decoration: none;
        button {
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .ft-list {
        padding: 24px 0 64px;
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          /* flex-direction: column; */
          flex-direction: row;
          gap: 16px;
          li {
            display: flex;
            align-items: center;
            gap: 24px;
            flex-basis: 33%;
            .icon {
              height: unset;
              width: unset;
              margin-bottom: 36px;
              /* height: 64px;
							width: 64px; */
              @media (max-width: 930px) {
                margin-bottom: 16px;
              }
            }
            .title {
              @media (max-width: 1140px) {
                font-size: 1rem;
              }
              @media (max-width: 930px) {
                margin-bottom: 16px;
              }
            }
            @media (max-width: 1200px) {
              gap: 12px;
            }
          }
          @media (max-width: 930px) {
            flex-wrap: wrap;
          }
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .section-content {
      max-width: 582px;
      width: 50%;
      & p {
        @media (max-width: 930px) {
          font-size: 16px;
        }
        @media (max-width: 768px) {
          font-size: var(--font-size-base);
        }
        @media (max-width: 575px) {
          font-size: 0.9444rem;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        max-width: unset;
      }
    }
  }
  .ft-list:not(.section-title .ft-list) {
    padding-left: 0;
    padding-right: 0;
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
`;

/* Render Component */
const AboutGuide = ({ aboutGoodHotelGuide }) => {
  return (
    <AboutGuideStyles id="anchor_about-guide">
      <div className="content">
        <div className="section-title">
          {aboutGoodHotelGuide.subheading && (
            <h5 className="subheading">{aboutGoodHotelGuide.subheading}</h5>
          )}

          <h2 className="title">{aboutGoodHotelGuide.title}</h2>

          {aboutGoodHotelGuide.navItem && (
            <GetLinks
              navItem={aboutGoodHotelGuide.navItem}
              buttonStyle="white-btn large"
            />
          )}
          <FeatureList
            featureList={aboutGoodHotelGuide.featureList}
            className="desktop"
          />
        </div>
        <div className="section-content">
          <p>{aboutGoodHotelGuide.aboutGoodHotelGuideDescription}</p>
        </div>
      </div>

      <FeatureList
        featureList={aboutGoodHotelGuide.featureList}
        className="mobile"
      />
    </AboutGuideStyles>
  );
};

export default AboutGuide;
