import React from "react";
import Layout from "./src/components/Layout";
import { RootProvider } from "./src/context/RootContext";

// Wrap Page in Layout Component - standard across all pages
const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// Wrap Root with Global Context information e.g. allPillarPages, allHotels ...
const wrapRootElement = ({ element }) => <RootProvider>{element}</RootProvider>;

// export const onInitialClientRender = () => {
//   document.getElementById("___loader").style.display = "none"
// }

export { wrapPageElement, wrapRootElement };

export function onRouteUpdate({ location, prevLocation }) {
  // if(location.pathname.includes("hotel-payment")){
  //   window.location.href = "https://buy.stripe.com/14k8wNgi2agWa4g144"
  // } 
  if (window.location.hash === "")
    window.scroll({ top: 0, left: 0, behavior: "instant" }); 
}
