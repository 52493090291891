// Import Core
import React from 'react'
// Import Utils
import { GetListLinks } from '../../utils/getNavItemLinks';

const Tab = ({ fullMenu, activeTab, setNavbarOpen, isMainMenu }) => {
	const currentTabItems = fullMenu.filter(hotel => hotel.title == activeTab.title)
	// console.log('activeTab', activeTab);
	// console.log('currentTabItems', currentTabItems);
	// console.log('currentTabItems[0]', currentTabItems[0]);

	const listMap = (listItem) => {
		if((listItem.items).length > 0) {
			return (
				<div key={listItem.name}>
					<div className={listItem.name}>
						<h5>{listItem.name}</h5>
						<ul>
							{listItem.items.map((item, index) => {
								return <GetListLinks isMainMenu={isMainMenu} setNavbarOpen={setNavbarOpen} item={item} index={index} key={index} />;
							})}
						</ul>
					</div>
				</div>
			)
		} else {
			return null
		}

	} 

  return (
    <>
			{currentTabItems[0].itemsList.map(listMap)}
    </>
  );
};

export default Tab