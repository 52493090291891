// Import Core
import { createGlobalStyle } from 'styled-components';
// Import Assets (Fonts)
import "../assets/fonts/typekitStyles.css";

const Typography = createGlobalStyle`
  :root {
    /** TYPOGRAPHY  **/
    // Body
    --font-family-base: "canada-type-gibson", sans-serif;
    /* --font-family-base: "canada-type-gibson", sans-serif; */
    // Headings
    --font-family-headings: "gill-sans-nova", sans-serif;

    --font-size-base: 18px; // will be 18px as specified in html

    --h1-font-size: 2.667rem; //48px
    --h2-font-size: 2rem; //36px
    --h3-font-size: 1.333rem; //24px
    --h4-font-size: 1.167rem; //21px
    --h5-font-size: 0.667rem; //12px
    --h6-font-size: 1rem; //18px

    --h1-mobile-font-size: 1.778rem; //32px
    --h2-mobile-font-size: 1.333rem; //24px
    --h3-mobile-font-size: 1.167rem; //21px
    --h4-mobile-font-size: 1rem; //18px
    --h5-mobile-font-size: 0.667rem; //12px
    --h6-mobile-font-size: 0.944rem; //17px
  }

  html {
    font-family: var(--font-family-base), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: var(--font-size-base);
    font-weight: 300;
    font-display: swap;
  }

  body {
    font-size: var(--font-size-base);
    font-family: var(--font-family-base);
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    font-style: normal;
  }
  h1, h2, h3, h4, h6 {
    font-family: var(--font-family-headings);
    font-weight: 600;
  }

  h1 {
    font-size: var(--h1-font-size);
    letter-spacing: -0.03em;
    line-height: 125%;
    text-transform: capitalize;
    @media (max-width: 575px) {
      font-size: var(--h1-mobile-font-size);
      letter-spacing: -0.01em;
    }
  }
  h2 {
    font-size: var(--h2-font-size);
    line-height: 125%;
    text-transform: capitalize;
    @media (max-width: 575px) {
      font-size: var(--h2-mobile-font-size);
    }
  }
  h3 {
    font-size: var(--h3-font-size);
    line-height: 150%;
    text-transform: capitalize;
    @media (max-width: 575px) {
      font-size: var(--h3-mobile-font-size);
      line-height: 125%;
    }
    &.small {
      font-size: var(--h3-mobile-font-size);
    }
  }
  h4 {
    font-size: var(--h4-font-size);
    line-height: 150%;
    text-transform: capitalize;
    @media (max-width: 575px) {
      font-size: var(--h4-mobile-font-size);
    }
  }
  h5 {
    font-family: var(--font-family-base);
    font-weight: 800;
    font-size: var(--h5-font-size);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 150%;
    @media (max-width: 575px) {
      font-family: var(--h5-mobile-font-size);
      letter-spacing: 1px;
    }
  }
  h6 {
    font-size: var(--h6-font-size);
    line-height: 150%;
    @media (max-width: 575px) {
      font-family: var(--h6-mobile-font-size);
    }
  }

  p, a {
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-base);
    line-height: 160%;
    /* To preserve line breaks from sanity text areas */
    white-space: pre-wrap;

    @media (max-width: 575px) {
      font-size: 0.9444rem; // 17px
      line-height: 150%;
    }
  }
  p.small, .small {
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 300;
    font-size: 0.889rem; //16px
    line-height: 150%;

    @media (max-width: 575px) {
      font-size: 0.833rem; //15px
    }
  }
  a.small {
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 300;
    font-size: 0.889rem; //16px
    line-height: 150%;

    @media (max-width: 575px) {
      font-size: 0.778rem; //14px
    }
  }
  p.x-small,
  a.x-small {
    font-family: var(--font-family-base);
    font-weight: 300;
    font-size: 0.778rem; //14px
    line-height: 150%;
    font-weight: 300;
  }
  blockquote {
    font-family: var(--font-family-base);
    font-weight: 300;
    font-size: 1rem; //18px
    font-weight: normal;
    line-height: 175%;
    font-style: italic;
    @media (max-width: 575px) {
      font-size: 0.9444rem; // 17px
      line-height: 150%;
    }
  }

  a, .card_link {
    color: var(--primary-500);
    text-decoration: underline;
    text-decoration-color: var(--primary-500);
    /* Chrome renders this weird with this font, so we turn it off */
    /* text-decoration-skip-ink: none; */
    cursor: pointer;
  }

  button, .btn, button[type='submit'] {
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 500;
    font-size: 0.833rem; //15px
    line-height: 100%;
    text-transform: uppercase;
  }
  button.large, .btn.large, button[type='submit'].large {
    font-size: 1rem; //18px
  }
  button.small, .btn.small, button[type='submit'].small {
    font-size: 0.722rem; //13px
  }

  input, textarea {
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 300;
    font-size: 0.889rem; //16px
    line-height: 128%;
  }
  input.large {
    font-size: 1rem; //18px
    line-height: 131%;
  }
  input.small {
    font-size: 0.778rem; //14px
  }

  label {
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 300;
    font-size: 0.778rem; //14px
    line-height: 160%;
    color: var(--neutral-900);
    &.large {
      font-size: 0.889rem; //16px
    }
    &.small {
      font-size: 0.722rem; //13px
    }
  }

  .center {
    text-align: center;
  }

  ul {
    li {
      list-style-position: inside;
    }
  }

`;

export default Typography;
