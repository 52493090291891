// Import Core
import React, { Suspense, lazy, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
// Import Components
import { Button } from '../../components/ui/Button';
// import Modal from '../ui/Modal';
// import LocationPopup from '../ui/LocationPopup';
import ToggleSwitch from '../ui/ToggleSwitch';
// Import Icons
import { FaSearch, FaTimes, FaChevronDown } from 'react-icons/fa';
// Import Utils
import useModal from '../../utils/useModal';
import { toSlug } from '../../utils/toSlug';
import Loader from '../blocks/Loader';
// Import lazy Components
const Modal = lazy(() => import('../ui/Modal'));
const LocationPopup = lazy(() => import('../ui/LocationPopup'));

const SearchBarStyle = styled.section`
	background-color: var(--neutral-50);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 80px;
	z-index: 3;
	width: 100vw;
	@media (max-width: 768px) {
		min-height: 50px;
		transition: all 0.3s ease-in-out;
		height: fit-content;
		&:has(.searchbar.show) {
			min-height: 100px;
		}
	}
	.searchbar {
		display: flex;
		align-items: center;
		margin: auto 40px;
		gap: 40px;
		height: 100%;
		@media (max-width: 768px) {
			display: none;
			height: 0;
			flex-direction: column;
			margin: 40px 24px;
			align-items: start;
			gap: 24px;
			transition: all 0.3s ease-in-out;
		}
		@media (max-width: 768px) {
			&.show {
				display: flex;
				height: fit-content;
			}
		}
		.locationWrapper {
			flex: 1;
			@media (max-width: 768px) {
				width: 100%;
			}
			> span {
				display: none;
				@media (max-width: 768px) {
					display: block;
				}
			}
			.location {
				background-color: var(--white);
				border: 1px solid var(--neutral-300);
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				font-size: 1rem;
				line-height: 131%;
				color: var(--neutral-500);
				text-decoration: none;
				padding: 12px 16px;
				max-width: 376px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@media (max-width: 768px) {
					max-width: unset;
				}
				svg {
					font-size: 12px;
				}
			}
		}
		.offers {
			flex: 0.5;
			display: flex;
			justify-content: space-around;
			align-items: center;
			@media (max-width: 768px) {
				gap: 10px;
			}
			input {
				margin-left: 12px;
			}
		}
		.search-icon-button {
			flex: 1;
			max-width: 376px;

			@media (max-width: 768px) {
				width: 100%;
				max-width: unset;
			}
		}
	}

	.btn.mob {
		display: none;
		@media (max-width: 768px) {
			display: flex;
			/* margin: 32px auto; */
			background-color: var(--neutral-900);

			margin: 16px auto;
		}
	}
`;

const SearchBar = () => {
	const [formOptions, setFormOptions] = useState({
		locationId: '',
		locationName: 'Choose Location',
		// facilities: [],
		offers: false,
	});
	const [isModalOpen, toggle] = useModal();
	const [formOpen, setFormOpen] = useState(false);

	const handleSubmit = (e) => {
		// Prevent result page from refreshing
		if (e) {
			e.preventDefault();
		}

		// // Filter the flattened hotel array for searched location
		// const searchInputResults = filterHotelsFromSearchValue(flattenedHotels, formOptions.locationName).map(hotel => hotel.id);

		// // Then get full hotel details from id
		// let hotelSearchResults = getHotelsOrPagesFromIds(allHotels, searchInputResults);

		// // If special offers is true, filter for hotels with offers
		// if (formOptions.offers) {
		//   hotelSearchResults = hotelSearchResults.filter(hotel => {
		//     return specialOfferHotelIds.includes(hotel._id)
		//   })
		// }

		// Push to array of results that we will pass through to the searh-results page
		// navigate('/search-results', { state: { hotelSearchResults } });

		// Need to remove 'Co.' from String as it affects search! e.g. 'Co. Monaghan' should just be 'Monaghan'
		let locationString = formOptions.locationName;
		if (locationString.includes('Co.')) {
			locationString = locationString.replace('Co. ', '');
		}
		navigate(
			`/search-results?location=${toSlug(locationString)}&offers=${
				formOptions.offers
			}`
		);

		// remove className 'show' on submit to close the search bar on mobile
		openForm();
	};

	const changeOffers = () => {
		setFormOptions((formOptions) => ({
			...formOptions,
			...{ offers: !formOptions.offers },
		}));
	};

	const openForm = () => {
		setFormOpen(!formOpen);
	};

	return (
		<SearchBarStyle>
			<Button onClick={openForm} role='button' className='btn large mob'>
				<span>{formOpen ? 'CLOSE' : 'SEARCH'}</span>
				{formOpen ? <FaTimes /> : <FaSearch />}
			</Button>

			<div
				className={`searchbar ${formOpen ? 'show' : ''}`}
				onSubmit={handleSubmit}>
				<div className='locationWrapper'>
					<span>Location</span>
					<a
						role='button'
						tabIndex='0'
						target='_self'
						className='location'
						onClick={toggle}>
						<span>{formOptions.locationName}</span>
						<FaChevronDown />
					</a>
				</div>
				{/* <a href="#" className="facilities" >Select Facilities</a> */}

				<Suspense fallback={<Loader />} key='chooseLocation'>
					<Modal
						id='chooseLocation'
						isModalOpen={isModalOpen}
						hide={toggle}
						fullscreen={true}
						content={
							<Suspense fallback={<Loader />} key='locationPopup'>
								<LocationPopup
									hide={toggle}
									setFormOptions={setFormOptions}
								/>
							</Suspense>
						}
					/>
				</Suspense>

				{/* <label className="offers" htmlFor="offers">Special Offers */}
				{/* <input name="offers" 
            type="checkbox" 
            onChange={changeOffers}
          /> */}
				{/* </label> */}
				<div className='offers'>
					Special Offers
					<ToggleSwitch checked={formOptions.offers} onChange={changeOffers} />
					{/* <label className="toggle-switch">
          <input type="checkbox" checked={formOptions.offers} onChange={changeOffers} />
          <span className="switch" />
        </label> */}
				</div>

				{/* <input className="" type="submit" value="Search" /> */}
				<Button
					onClick={() => handleSubmit()}
					role='link'
					className='large search-icon-button'
					disabled={!formOptions.locationId}
				>
					<span>Search</span>
					<FaSearch />
				</Button>
			</div>
		</SearchBarStyle>
	);
};

export default SearchBar;
