// Import Core
import React from "react";
import styled from "styled-components";

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitchStyles = styled.label`
  &.toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    input[type="checkbox"] {
      display: none;
    }
    .switch {
      position: absolute;
      cursor: pointer;
      background-color: var(--white);
      border: 1px solid var(--neutral-400);
      border-radius: 100px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
      &::before {
        position: absolute;
        content: "";
        left: 1px;
        top: 1px;
        width: 16px;
        height: 16px;
        background-color: var(--neutral-400);
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }
    input[type="checkbox"]:checked + .switch::before {
      transform: translateX(16px);
      background-color: var(--white);
    }
    input[type="checkbox"]:checked + .switch {
      background-color: var(--primary-500);
      border-color: var(--primary-500);
    }
}

`;

const ToggleSwitch = ({checked, onChange}) => {
  return (
    <ToggleSwitchStyles className="toggle-switch">
      <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="switch" />
    </ToggleSwitchStyles>
  );
};


export default ToggleSwitch