// Import Core
import { createGlobalStyle } from "styled-components";
// Import Assets
import chevronLeft from "../assets/images/chevronLeft.svg";

const GlobalStyles = createGlobalStyle`
  :root {
    /**  COLORS  **/
    /* Primary */
    --primary: #8C0052;
    --primary-900: #2A0019;
    --primary-800: #460029;
    --primary-700: #540031;
    --primary-600: #700042;
    --primary-500: #8C0052;
    --primary-400: #A33375;
    --primary-300: #BA6697;
    --primary-200: #DDB2CB;
    --primary-100: #F3E5EE;
    --primary-50: #FCF7FA;
    /* Neutral */
    --black: #212121;
    --neutral-900: #212121;
    --neutral-800: #424242;
    --neutral-700: #616161;
    --neutral-600: #757575;
    --neutral-500: #9E9E9E;
    --neutral-400: #BDBDBD;
    --neutral-300: #E0E0E0;
    --neutral-200: #EEEEEE;
    --neutral-100: #F5F5F5;
    --neutral-50: #FAFAFA;
    --white: #FFFFFF;
    /* Secondary */
    --secondary-900: #330800;
    --secondary-800: #803100;
    --secondary-700: #B36100;
    --secondary-600: #E6A100;
    --secondary-500: #FFC700;
    --secondary-400: #FFD233;
    --secondary-300: #FFE380;
    --secondary-200: #FFEEB2;
    --secondary-100: #FFF4CC;
    --secondary-50: #FFFCF2;
    /* Feeback Colours */
    /* Error */
    --error-900: #2D080F;
    --error-800: #430D16;
    --error-700: #86192C;
    --error-600: #B2223B;
    --error-500: #DF2A4A;
    --error-400: #E23F5C;
    --error-300: #EC7F92;
    --error-200: #F9D4DB;
    --error-100: #FCEAED;
    --error-50: #FEF9FA;
    /* Warning */
    --warning-900: #320600;
    --warning-800: #641700;
    --warning-700: #943400;
    --warning-600: #DB7500;
    --warning-500: #F29100;
    --warning-400: #F39C19;
    --warning-300: #F7BD66;
    --warning-200: #FBDEB2;
    --warning-100: #FEF4E5;
    --warning-50: #FFFCF7;
    /* Success */
    --success-900: #020E09;
    --success-800: #031C11;
    --success-700: #07452B;
    --success-600: #0C6E45;
    --success-500: #0F8A56;
    --success-400: #3FA178;
    --success-300: #6FB99A;
    --success-200: #B7DCCC;
    --success-100: #E7F3EE;
    --success-50: #F8FBFA;

    /** TYPOGRAPHY  **/
    --body-color: --black;
  }
  * {
    margin: 0;
    padding: 0;
  }
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: antialiased;
    font-weight: 400;
  }
  @-moz-document url-prefix() {
    body {
      font-weight: lighter !important;
    }
  }
  html {
    background-color: var(--white);
    /* scroll-behavior: smooth; */
  }
  
  body {
    color: var(--body-color);
    line-height: 160%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
  }

  .main-content {
    max-width: calc(1440px + 240px);
    margin: 0 auto;
    width: 100%;
  }
  /* Pixelate images on load - ignore error, it works! */
  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } */

  /* fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--primary-500);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  } */

  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } */

  /* Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px; /* for horizontal scrollbars */
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-500) var(--neutral-100);
  }
  ::-webkit-scrollbar-track {
    background: var(--neutral-100);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-500) ;
    border-radius: 6px;
    border: 3px solid var(--neutral-100);
  }

  hr {
    height: 0;
    border: none;
    border-top: 1px solid var(--primary-700);
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
  img {
    max-width: 100%;
  }

  input, textarea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    /* max-width: 232px;
    height: 40px; */
    background: var(--neutral-50);
    border-radius: 4px;
    border: 1px solid var(--neutral-300);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
  [type="checkbox"], [type="radio"] {
    box-shadow: none;
  }

  .link {
    cursor: pointer;
  }

  .btn-holder {
    a {
      text-decoration: none;
    }
  }

  .no-results {
    text-align: center;
  }

  /* Modal open prevent scroll */
  /* .modal-open {
    overflow: hidden;
  } */
  .tooltip {
    color: var(--neutral-900);
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;
    p {
      margin-bottom: 0;
    }
    svg {
      color: var(--primary-500);
      min-width: 14px;
    }
  }

  .breadcrumb {
    color: var(--primary-500);
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding: 8px 40px;
    gap: 40px;
    .breadcrumb__list {
      display: flex;
      list-style: none;
      [aria-current]:not([aria-current="false"]) {
        text-decoration: none;
        color: var(--neutral-600);
      }
      .breadcrumb__separator {
        padding: 0 16px;
        color: var(--neutral-600);
        margin: auto 0;
      }
    }
    @media (max-width: 992px) {
      display: none;
    }
  }

  /* Cards & Card Carousel */
  a.card {
    &:hover {
      /* transform: scale(1.01); */
      box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.3);
    }
  }
  .card {
    background: var(--white);
    width: 376px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 40px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.1);
    transition: all ease 200ms;
    text-decoration: none;
    color: var(--neutral-800);
    @media (max-width: 992px) {
      gap: 32px;
    }
    @media (max-width: 768px) {
      gap: 24px;
    }
  

    
    .card_title {
      color: var(--neutral-900);
    }
    .gatsby-image-wrapper {
      width: 100%;
      min-height: 319px;
      height: 319px;
      @media (max-width: 768px) {
        height: 170px;
        min-height: unset;
      }
      img {
        object-fit: cover;
      }
    }
    .card-body {
      padding: 0 40px 40px 40px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 100%;
      @media (max-width: 768px) {
        height: fit-content;
        padding: 0 24px 32px;
      }
      .card_description {
        /* flex: 1; */
      }
      .card_link {
        justify-content: flex-start;
        text-align: left;
      }
    }
    
    &.card_horizontal {
      flex-direction: row;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    /* &.card_additional {
      flex-direction: row;
    } */
    &.no-link {
      cursor: default;
      pointer-events: none;
    }
  }
  /* Special Offer Banner */
  .corner-banner {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100px 0 0;
    border-color: var(--primary-500) transparent transparent transparent;
    line-height: 0;
    position: absolute;
    top: 0;
    filter: alpha(opacity=100);
    opacity: 1;
    z-index: 49;
    
    span {
      text-transform: uppercase;
      color: var(--white);
      top: -82px;
      left: -13px;
      line-height: 15px;
      font-size: 15px;
      position: absolute;
      display: block;
      text-align: center;
      width: 100px;
      transform: rotate(-45deg);
    }
  }

  /* pill label InGuide status of the hotel */
.pill-label{
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 3px; 
  color: var(--white);
  width: fit-content;
  &.in-guide{
    background-color: var(--primary-500);
  }
  &.not-in-guide{
    background-color: var(--neutral-600);
  }
}



  /** Slick Slider */
  .slick-slider {
    width: 100%;
    height: 100%;

    .slick-list {
      /* THIS MESSES UP RESULT CARDS SLIDER */
      height: 100%;

      .slick-track {
        height: 100%;
        max-height: inherit;
        display: flex !important;
        margin: 0;
        .slick-slide {
          height: auto !important;
          div {
            /* THIS MESSES UP RESULT CARDS SLIDER */
            height: 100%;
            position: relative;
          }
        }
      }
    }

    .slick-arrow {
      z-index: 1;
      /* position: absolute;
      left: 50px;
      right: 50px;
      */
      width: 67px;
      height: 67px; 
      background: var(--primary-500);
      border-radius: 50%;
      /* left: unset;
      top: unset; */
      &:before {
        content: '';
        background-image: url(${chevronLeft});
        filter: invert(100%) sepia(2%) saturate(2%) hue-rotate(354deg)
          brightness(118%) contrast(100%);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: block;
      }
      &.slick-prev {
        /* bottom: 100px; */
      }
      &.slick-next {
        /* bottom: 18px; */
        &:before {
          transform: rotate(180deg);
        }
      }
    }


    &.invisible-arrows {
        .slick-arrow {
        background: transparent;border-radius: 0;
        position: absolute;
        width: 67px;
        height: 90%;
        border-radius: 0;
        right: unset;
        left: unset;
        top: 45%;
        bottom: 0;
      &:before {
        content: '';
        background-image: unset;
        
      }
      &:hover {
          opacity: 0.25;
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${chevronLeft});
            filter: invert(100%) sepia(2%) saturate(2%) hue-rotate(354deg)
              brightness(118%) contrast(100%);
          }
        }
        &.slick-prev {
          margin-left: 0;
        }
        &.slick-next {
          right: 0;
          /* &:before {
            transform: rotate(180deg);
          } */
        }
      }
    }

    .slick-dots {
      margin: 0;
      width: inherit;
      li {
        button {
          &:before {
            font-size: 12px;
            color: var(--neutral-300);
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              width: 10px;
              height: 10px;
              font-size: 0px;
              color: transparent;
              border-radius: 50%;
              border: 1px solid var(--neutral-300);
              margin: 4px 0 0 4px;
            }
          }
        }
      }
    }

    /* Limit the slick dots to 3 */
    .slick-dots {
      text-align: center;
    }
    .slick-dots li {
      display: none;
      margin: 0 5px;
    }
    .slick-dots li.slick-active,
    .slick-dots li.slick-active + li,
    .slick-dots li.slick-active + li + li {
        display: inline-block;
    }

    .slick-dots li:nth-last-child(1),
    .slick-dots li:nth-last-child(2),
    .slick-dots li:nth-last-child(3) {
        display: inline-block;
    }

    .slick-dots li.slick-active ~ li:nth-last-child(1),
    .slick-dots li.slick-active ~ li:nth-last-child(2),
    .slick-dots li.slick-active ~ li:nth-last-child(3) {
        display: none;
    }
    .slick-dots li.slick-active + li + li:nth-last-child(3),
    .slick-dots li.slick-active + li + li:nth-last-child(2),
    .slick-dots li.slick-active + li + li:nth-last-child(1),
    .slick-dots li.slick-active + li:nth-last-child(3),
    .slick-dots li.slick-active + li:nth-last-child(2),
    .slick-dots li.slick-active + li:nth-last-child(1){
        display: inline-block;
    }
  }


  &#map-wrapper {
    .slide-in-map {
      .close-map-btn {
        display: none;
      }
    }

    &.slide {
      .slide-in-map { 
        height: 40vh;
        .close-map-btn {
          display: block;
          position: absolute;
          z-index: 1;
          background-color: var(--primary);
          color: var(--white);
          border-radius: 50%;
          padding: 8px;
          margin: 10px;
        }
      }
    }
  }

  /** Slide in map */
  @media (min-width: 768px) {
	
    &#map-wrapper {
      display: flex;
      flex-direction: row-reverse;

      transition: all .25s ease-in-out;
        //cubic-bezier(0.75, -0.25, 0.25, .75);
      position: relative;
      transform: translateX(0);
      .results-container {
        width: 100%;
        margin-left: auto;
        transition: all 0.25s ease-in-out;
      }

      .slide-in-map { 
        width: 0;
        transform: translateX(-0);
        transition: 0.25s ease-out all;

    .map {
      /* min-height: 328px;
      max-height: 350px; */
      height: inherit;
      > div {
        min-height: inherit;
      }
    }

    .map_marker {
      margin-top: -37px;
      height: 37px;
      width: 29px;
    }


      }
      &.slide {
        transform: translateX(-40vw) ;
        .results-container {
          width: calc(100% - 40vw);
          margin-left: auto;
        }
        @media (max-width: 768px) {
          transform: unset;
          .results-container {
            width: 100%;
          }
        }
        // Need to change layout of result cards when map is open
        .result-cards {
          padding: 64px 32px 64px 32px;
          .cards {
            display: flex;
            columns: 2;
            gap: 36px 64px;
            justify-content: flex-start;
            .card {
              flex-direction: column;
              gap: 0;
              width: calc(50% - 36px);
              height: auto!important;
              .slick-slider {
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                height: 200px!important;
                .slick-list {
                  .slick-track {
                    .slick-slide {
                      div {
                        .gatsby-image-wrapper {
                            height: 200px;
                        }
                      }
                    }
                  }
                }
                .slick-dots {
                    right: unset;
                    left: 8px;
                    bottom: 8px;
                }
              }
              .gatsby-image-wrapper {
                  height: 200px;
                  width: 100%;
                  min-height: 200px;
                  min-width: unset;
                  max-width: unset;
                  height: initial;
              }
              .card-body {
                  padding: 24px;
                  width: calc(100% - 48px);
                  .card_description {
                    flex: 1;
                  }
              }
            }
          }
        }

        .additional-cards {
          --grid-item--min-width: 200px;
          @media (max-width: 992px) {
            --grid-item--min-width: 200px;
          }
        }

        .slide-in-map {
          width: 40vw;
          /* height: 90vh; */
          height: calc(90vh - 80px);
          position: sticky;
          margin-right: -40vw;
          top: 107px;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {	
    &#map-wrapper {
      &.slide {
        // Need to change layout of result cards when map is open
        .result-cards {
          padding: 64px 64px 64px 120px;
        }
      }
    }
  }

  /** Mobile Map */
  @media (max-width: 768px) {
	
    &#map-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      .results-container {
        width: 100%;
      }
      
      
      .slide-in-map {
        height: 0;
        width: 100vw; 
        max-height: 540px;
        transition: all 0.25s ease-out;
        .map {
          /* min-height: 328px;
          max-height: 350px; */
          height: inherit;
          > div {
            min-height: inherit;
          }
        }

        .map_marker {
          margin-top: -37px;
          height: 37px;
          width: 29px;
        }
      }
    }
  }

  /** Standard Cards - used in Cards.js & Archive Pages */
  .cards_wrapper {
		padding: 40px 120px 64px;

		@media (max-width: 992px) {
			padding: 40px 64px;
		}
		@media (max-width: 768px) {
			padding: 40px 24px 32px;
		}
	
	.cards {
		--grid-layout-gap: 36px;
		--grid-column-count: 4;
		--grid-item--min-width: 273px;
		@media (max-width: 992px) {
			--grid-layout-gap: 16px;
			--grid-item--min-width: 273px;
		}
		
		/** Calculated values */
		--gap-count: calc(var(--grid-column-count) - 1);
		--total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
		--grid-item--max-width: calc(
			(100% - var(--total-gap-width)) / var(--grid-column-count)
		);

		display: grid;
		grid-template-columns: repeat(
			auto-fill,
			minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
		);
		grid-gap: var(--grid-layout-gap);

		/* Default - Smaller Cards */
		.card_default {
			/* box-shadow: none;
			background-color: transparent;
			flex-direction: row; */
			width: 100%;
			/* height: 200px; */
			/* gap: 10px;
			margin: 0 0 64px 0;
			padding: 0; */
			gap: 0;

			@media (max-width: 992px) {
				margin: 0 0 64px 0;
			}
			@media (max-width: 768px) {
				margin: 0 0 16px 0;
			}

			.gatsby-image-wrapper {
				width: 100%;
				height: 200px;
				min-width: 200px;
				min-height: 200px;
			}

			.card_body {
				padding: 16px 24px;
				gap: 16px;
				display: flex;
				align-items: left;
				flex-direction: column;
				justify-content: flex-start;
        height: 100%;

        .card_content {
          gap: 16px;
          display: flex;
          flex-direction: column;
        }

				.card_link {
					text-decoration: none;
					/* transition: 0.3s all ease-in-out; */
					justify-content: flex-start;
          margin-top: auto;
				}
        a:has(.card_link) { 
          text-decoration: none;
        }
			}
		}

		/* Compact Cards - no border, just title and image */
		.card_default-compact {
			box-shadow: none;
			background-color: transparent;
			flex-direction: row;
			width: 100%;
			height: 100px;
			gap: 10px;
			margin: 0 0 64px 0;
			padding: 0;

			@media (max-width: 992px) {
				margin: 0 0 64px 0;
			}
			@media (max-width: 768px) {
				margin: 0 0 64px 0;
			}

			.gatsby-image-wrapper {
				width: 100px;
				height: 100px;
				min-width: 100px;
				min-height: 100px;
			}

			.card_body {
				padding: 0;
				display: flex;
				align-items: left;
				flex-direction: column;
				gap: 8px;
				justify-content: center;

				.card_link {
					text-decoration-color: var(--neutral-900);
					transition: 0.3s all ease-in-out;
				}
        a:has(.card_link) { 
          text-decoration: none;
        }
			}
		}
	}

}

/* Pillar Pages */
.pillar-content {
  padding: 32px 120px 64px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    padding: 32px 64px;
    gap: 32px;
  }
  @media (max-width: 768px) {
    padding: 40px 24px;
  }
}

.placeholder-hero-image {
  position: relative;
  /* height: 100%; */
  width: 100%;
  object-fit: contain !important;
  object-position: center;
  background-color: var(--primary);
  opacity: 0.5;
  height: calc(666px - 80px);
  overflow: hidden;

  @media (max-width: 768px) {
    height: calc(600px - 80px);
  }
}

`;

export default GlobalStyles;
