// Import Core
import React, { useState } from 'react';
import styled from 'styled-components';
// Import Components
import { Button } from '../ui/Button';
// Import Icons
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
// Import Assets
// import iconLogo from '../../assets/images/icon-logo.svg';
import iconLogo from '../../assets/images/logo/GHG_logo_white-icon.svg';

/* Styled Components */
const FloatingButtonStyles = styled(Button)`
	position: absolute;
	right: 0;
	margin-right: 40px;
	bottom: -43px;
	height: 43px;
	border-radius: 0 0 8px 8px;
	text-transform: capitalize;
	gap: 16px;
	z-index: 2;
	.floating-btn-logo {
		height: 26px;
		width: auto;
	}
	@media only screen and (max-width: 768px) {
		display: none;
	}
`;

/* Render Component */
const FloatingButton = ({ className, title, location }) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClick = (e) => {
		// If homepage we don't want the chevron changing onclick
		if (location.pathname !== '/') {
			setIsOpen(!isOpen);
		}
	};

	return (
		<FloatingButtonStyles className={className} onClick={handleClick} aria-label={title}>
			{isOpen ? <FaChevronUp /> : <FaChevronDown />}
			<span>{title}</span>
			{/* ! FIX: Change to Static Gastby image!!!!! */}
			<img src={iconLogo} alt='icon-logo' className='floating-btn-logo' />
		</FloatingButtonStyles>
	);
};

export default FloatingButton;
