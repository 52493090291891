// Import Core
import React, { useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
// Import Libraries
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js'
import { v4 as uuidv4 } from 'uuid';
// Import Components
import ProductList from "../ProductList";
// Import Icons
import { FaChevronDown } from 'react-icons/fa';
// Import Utils
import { getCurrentDate } from '../../../utils/getCurrentDate';
// Import Assets
import phoneCodes from '../../../utils/phoneCodes'

/* Styled Components */
const VoucherFormStyles = styled.div`
  form {
    .address {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .voucher-value {
      width: calc(50% - 18px);
    }
  }
`;

const VoucherForm = ({ location }) => {
  // Initiate forms
  const { register, handleSubmit, formState: { errors} } = useForm();
  // Create useStates for the custom dropdowns
  const [selectedValue, setSelectedValue] = useState({
    name: "",
    priceId: ""
	});
  const [valueDropdownOpen, setValueDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  // const [loading, setLoading] = useState(true);

  // Used to check for clicks outside of dropdowns
	const node = useRef();

  // Temporary voucher values
  const initialValue = 100;
  const endValue = 1000;
  const voucherArray = [];
  for (let n = initialValue; n <= endValue; n += 50) {
    voucherArray.push(n.toString());
  }

  // Toggle open/close of dropdown lists
  const toggle = (type) => () => {
    if(type === 'country') {
      setCountryDropdownOpen(!countryDropdownOpen);
      setValueDropdownOpen(false);
    } else {
      setValueDropdownOpen(!valueDropdownOpen);
      setCountryDropdownOpen(false);
    }
	}

  // Updates useState values when dropdown option clicked
  const onOptionClicked = (option, type) => () => {
    type === 'country' ? setSelectedCountry(option)
    : setSelectedValue(valueData => ({
      ...valueData,
      ...{ name: option.name },
      ...{ priceId: option.default_price }
    }));
		// Close dropdowns
		setValueDropdownOpen(false);
		setCountryDropdownOpen(false);
	};

  // Handle form submit
  const handlePost = async (formData) => {    
    // Create unique form ID
    const uuid = uuidv4();
    formData.uuid = uuid;

    // TODO: LOADING ICON
    // // Show loading icon
    // setLoading(true);

    // Set current date and dropdown values
    formData.date = getCurrentDate();
    formData.vouchervalue = selectedValue.name;
    formData.country = selectedCountry;
    
    // SEND DATA TO SANITY
    const api = 'https://1gqu4qfy3g.execute-api.eu-west-2.amazonaws.com/staging/';
    axios
      .post(api, { formType: 'new-form', body: formData })
      .then((response) => {
        console.log('response', response);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });

    // HANDLE STRIPE PAYMENT
    const domain = location.origin;


    // const stripe = await loadStripe('pk_test_51JjpAfIoqDNPFHpBXJxDQu6q15J3GVNjWAOu4xloYQFVquYFvT962ttKoZ7E6d6sV3oYoR9JipftNnpo96ySoeRk00H9PAYvlo')
    // const { error } = await stripe.redirectToCheckout({
    //   lineItems: [{
    //     // price: 'price_1M9QXuIoqDNPFHpBq7lxAMWK',
    //     price: selectedValue.priceId,
    //     quantity: 1
    //   }],
    //   mode: 'payment',
    //   clientReferenceId: uuid,
    //   successUrl: domain + '/form-confirmation/?type=voucher&id=' + uuid + '&status=complete',
    //   cancelUrl: domain + '/form-confirmation/?type=voucher&id=' + uuid + '&status=cancelled',
    //   customerEmail: formData.email,
    //   billingAddressCollection: 'required'
    // })

    let stripePromise;

    const getStripe = () => {
      if (!stripePromise) {
        // TODO:  Once live - no test key - add to env
        // stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)
        stripePromise = loadStripe('pk_test_51JjpAfIoqDNPFHpBXJxDQu6q15J3GVNjWAOu4xloYQFVquYFvT962ttKoZ7E6d6sV3oYoR9JipftNnpo96ySoeRk00H9PAYvlo')
      }
      return stripePromise;
    };

    const stripe = await getStripe();

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        // price: 'price_1M9QXuIoqDNPFHpBq7lxAMWK',
        price: selectedValue.priceId,
        quantity: 1
      }],
      mode: 'payment',
      clientReferenceId: uuid,
      successUrl: domain + '/form-confirmation/?type=voucher&id=' + uuid + '&status=complete',
      cancelUrl: domain + '/form-confirmation/?type=voucher&id=' + uuid + '&status=cancelled',
      customerEmail: formData.email,
      billingAddressCollection: 'required'
    })


  }

  return (
    <VoucherFormStyles>
      <form
        onSubmit={handleSubmit(handlePost)}
        name="voucher-form"
        method="POST"
        // action="/form-confirmation/"
        honeypot="got-ya"
        id="voucher-form"
      >

        <input type="hidden" name="form-name" value="voucher-form" />
        <input
          type="hidden"
          name="formId"
          value="voucher-form"
          {...register('formId')}
        />

        <h5>Your Name</h5>
        <div className="two-col">
          <label htmlFor="firstname">
            <p>First Name</p>
            {errors && errors.firstname && <p className="error-msg" role="alert">Please complete this field</p>}
            <input name="firstname" {...register('firstname', ({ required: true }))} />
          </label>
          <label htmlFor="lastname">
            <p>Last Name</p>
            {errors && errors.lastname && <p className="error-msg" role="alert">Please complete this field</p>}
            <input name="lastname" {...register('lastname', ({ required: true }))} />
          </label>
        </div>
        <h5>Recipient's Name</h5>
        <div className="two-col">
          <label htmlFor="recipientfirstname">
            <p>First Name</p>
            {errors && errors.recipientfirstname && <p className="error-msg" role="alert">Please complete this field</p>}
            <input name="recipientfirstname" {...register('recipientfirstname', ({ required: true }))} />
          </label>
        
          <label htmlFor="recipientlastname">
            <p>Last Name</p>
            {errors && errors.recipientlastname && <p className="error-msg" role="alert">Please complete this field</p>}
            <input name="recipientlastname" {...register('recipientlastname', ({ required: true }))} />
          </label>
        </div>

        <div className="address">
          <label htmlFor="streetaddress">
            <p>Street Address</p>
              {errors && errors.streetaddress && <p className="error-msg" role="alert">Please complete this field</p>}
              <input name="streetaddress" {...register('streetaddress', ({ required: true }))} />
          </label>
          <label htmlFor="addressline2">
            <p>Address Line 2</p>
              <input name="addressline2" {...register('addressline2')} />
          </label>
          <div className="two-col">
            <label htmlFor="city">
              <p>City</p>
                {errors && errors.city && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="city" {...register('city', ({ required: true }))} />
            </label>      
            <label htmlFor="county">
              <p>County</p>
                {errors && errors.county && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="county" {...register('county', ({ required: true }))} />
            </label>
          </div> 
          <div className="two-col">
            <label htmlFor="postcode">
              <p>Post Code</p>
                {errors && errors.postcode && <p className="error-msg" role="alert">Please complete this field</p>}
                <input name="postcode" {...register('postcode', ({ required: true }))} />
            </label>      
            <label htmlFor="country">
              <p>Country</p>
              <input type="hidden" name="country" value="" {...register('country')}/>
              {/* Render custom select dropdown */}
              <div className="dropdown-container country">
                <div className={`dropdown-header container ${selectedCountry ? 'selected' : null}`} onClick={toggle('country')}>{selectedCountry || "United Kingdom"}
                  <FaChevronDown />
                </div>
                {countryDropdownOpen && (
                  <div className="dropdown-list-container">
                    <ul className="dropdown-list" ref={node}>
                      {phoneCodes.map((option, index) => (
                        <li className="list-item" onClick={onOptionClicked(option.name, 'country')} key={index}>
                          {option.name}
                        </li>))}
                    </ul>
                  </div>
                )}
              </div>
            </label>
          </div> 
        </div>
        
        <div className="two-col">
          <label htmlFor="email">
            <p>Email</p>
            {errors && errors.email && <p className="error-msg" role="alert">Please format email correctly</p>}
            <input
              name="email"
              placeholder="your@email.com"
              {...register('email', ({
                required: true,
                pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
              }))}
            />
          </label>
          <label htmlFor="telephone">
            <p>Your Telephone</p>
            {errors && errors.telephone && <p className="error-msg" role="alert">Please complete this field</p>}
            <input
              name="telephone" placeholder="+1 (123) 456-7890"
              {...register('telephone', ({
                required: true,
                // pattern: ,
              }))}
            />
          </label>
        </div>

        <label htmlFor="vouchervalue">
          <p>Voucher Value</p>
          {/* Register the input type */}
          <input type="hidden" name="vouchercode" value={selectedValue.name} {...register('vouchervalue')}/>
          {/* Render custom select dropdown */}
          <div className="dropdown-container voucher-value">
            <div className={`dropdown-header container ${selectedValue.name ? 'selected' : null}`} onClick={toggle('value')}>{selectedValue.name ? selectedValue.name : "£100 Voucher"}
            <FaChevronDown />
            </div>
            {/* Render list of available products */}
            <ProductList valueDropdownOpen={valueDropdownOpen} onOptionClicked={onOptionClicked}/>
          </div>
        </label>

        <input type="hidden" name="vouchercode" value="testcode" {...register('vouchercode')} />
        <input type="hidden" name="date" value=""{...register('date')}  />
        <input type="hidden" name="uuid" value=""{...register('uuid')}  />
        
        <label
          htmlFor="got-ya"
          style={{
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: "1px",
            width: "1px",
            margin: "-1px",
            padding: "0",
            border: "0",
          }}
        >
          Don’t fill this out if you're human:
          <input tabIndex="-1" name="got-ya" {...register('got-ya')} />
        </label>
        <div className="btn large">
          <button type="submit" /* onClick={handleSubmit(handlePost)}*/ >BUY NOW</button>
        </div>
      </form>
    </VoucherFormStyles>
  )
}

export default VoucherForm