// Import Core
import React from 'react'
import styled from 'styled-components';
// Import Tracking Utils
import handleTrackingLinkClicks from '../../utils/tracking/handleTrackingLinkClick';
// Import Components
import { Button } from '../ui/Button';

const SeeHotelWebsiteCTAStyle = styled.section`
  background-color: var(--neutral-50);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 3;
  width: 100vw;
  display: none;
  @media (max-width: 768px) {
    display: block;
    min-height: 50px;
    transition: all 0.3s ease-in-out;
    height: fit-content; 
  }
  .mob {
    text-decoration: none;
    .btn {
      @media (max-width: 768px) {
        display: flex;
        background-color: var(--neutral-900);
        margin: 16px auto;
      }
  }
  }
`;
const SeeHotelWebsiteCTA = ({website, hasPaid, bookingUrl, hotelName, slug}) => {
  const websiteUrl = website?.includes('https') ?   website : website?.replace('http', 'https');
	const bookingUrl2 = bookingUrl?.includes('https') ?  bookingUrl : bookingUrl?.replace('http', 'https');
  return (
    <SeeHotelWebsiteCTAStyle>
      {hasPaid &&
        <a href={websiteUrl} target='_blank' onClick={() => handleTrackingLinkClicks('Website', hotelName, slug )} className='mob see_hotel_website'>
          <Button className='btn large'>See Hotel Website</Button>
        </a>
      }

      {(!hasPaid && bookingUrl) &&
        <a href={bookingUrl2} target='_blank' onClick={() => handleTrackingLinkClicks('booking.com', hotelName, slug )} className='mob check_availability'>
          <Button className='btn large'>Check Availability</Button>
      </a>
      }
    </SeeHotelWebsiteCTAStyle>
  )
}

export default SeeHotelWebsiteCTA