// Filter through flattened object & return results that match inputted search word
const filterHotelsFromSearchValue = (arr, searchKey) => arr.filter((obj) =>
  Object.keys(obj).some((key) => {
    if (obj[key]) {
      // Split words entered into an array so we can check if all words match an item - if so then we can return the result
      let searchValues = searchKey.split(' ');
      let found = searchValues.every((item) => obj[key].includes(item.toLowerCase()))
      if (found) {
        return obj[key]
      }
      // return obj[key].includes(searchKey.toLowerCase())
    }
  })
);

const getSpecialOfferHotelIds = allSpecialOffers => allSpecialOffers.filter(offer => offer.hotelId !== null).map(offer => {
  // Check if current date falls within offer start / end dates
  const todayDate = new Date()
  const startDate = new Date(offer.startDate)
  const endDate = new Date(offer.endDate)

  if ((todayDate > startDate && todayDate < endDate && offer.enabled) === true) {
    return offer.hotelId._id
  }
})

const getFlattenedHotels = allHotels => {
  // FOLLOW UP: IS IT JUST PAID OR ALL
  // const paidHotels = allHotels.filter(hotel => hotel.displayOptions.paid)
  return allHotels.map((hotel) => {
    return {
      id: hotel._id.toLowerCase(),
      type: hotel._type.toLowerCase(),
      name: hotel.name.toLowerCase(),
      slug: hotel.slug.current.toLowerCase(),
      street: hotel.addressGroup?.street?.toLowerCase(),
      town: hotel.addressGroup?.town?.toLowerCase(),
      village: hotel.addressGroup?.village?.toLowerCase(),
      postCode: hotel.addressGroup?.postCode?.toLowerCase(),
      region: hotel.addressGroup?.region?.toLowerCase(),
      county: hotel.addressGroup?.county?.toLowerCase(),
      country: hotel.addressGroup?.country?.toLowerCase(),
      searchTags: hotel.hotelInformation?.searchTags?.map(tag => tag.label?.toLowerCase()).toString() || '',
    };
  });
}

const getFlattenedPillar = allPillarPages => {
  return allPillarPages.map((page) => {
    return {
      id: page._id.toLowerCase(),
      type: page._type.toLowerCase(),
      name: page.name.toLowerCase(),
      slug: page.slug.current.toLowerCase(),
      country: page.hotelSearchOptions.filterByCountry.length > 0 ? (page.hotelSearchOptions.filterByCountry).toString().toLowerCase() : null,
      county: page.hotelSearchOptions.filterByCounty ? page.hotelSearchOptions.filterByCounty.toLowerCase() : null,
      town: page.hotelSearchOptions.filterByTown ? page.hotelSearchOptions.filterByTown.toLowerCase() : null,
      searchTags: page.hotelSearchTags?.filterByTags?.map(tag => tag.label.toLowerCase()).toString() || '',
    };
  });
}

const getFlattenedBlog = allBlogPosts => {
  return allBlogPosts.map((post) => {
    return {
      id: post._id.toLowerCase(),
      type: post._type.toLowerCase(),
      name: post.name.toLowerCase(),
      slug: post.slug.current.toLowerCase(),
      category: post.blogOptions?.categories?.map(cat => cat.name.toLowerCase()).toString() || '',
    };
  });
}

const getFlattenedSpecialOffers = allSpecialOffers => {
  return allSpecialOffers.map((offer) => {
    return {
      id: offer._id,
      type: offer._type.toLowerCase(),
      name: offer.title.toLowerCase(),
    };
  });
}

export { filterHotelsFromSearchValue, getSpecialOfferHotelIds, getFlattenedHotels, getFlattenedPillar, getFlattenedBlog, getFlattenedSpecialOffers }