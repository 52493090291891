import React, {useContext, useState} from 'react';
// import { Link } from 'gatsby';
import styled from 'styled-components';
// Import Icons
import { FaTimes } from 'react-icons/fa';
// Import Components
import TabbedContent from './TabbedContent';
import Tab from './Tab';

/* Styled Components */
const MainMenuStyles = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #ffffff;
	z-index: 1031;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	visibility: hidden;
	opacity: 0;

	transition: all 0.35s;

	cursor: pointer;

	&.open {
		visibility: visible;
		opacity: 1;
	}
	
	.closeBtn {
		position: absolute;
		right: 3rem;
		top: 3rem;
	}

	a {
		pointer-events: all;
	}
`;

/* Render Component */
const MainMenu = ({ navbarOpen, setNavbarOpen, hamburgerMenu }) => {

	// console.log('hamburgerMenu 🍔', hamburgerMenu);

	const navContent = hamburgerMenu.map((item) => item.title)

	const [activeTab, setActiveTab] = useState(hamburgerMenu[0]);
	// const [isOpen, setOpen] = React.useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	// console.log('activeTab', activeTab);

	const handleClick = (event, index) => {
		// 'Scotland' or England
		const tabName = event.currentTarget.id
		// Find tab that matches selected tabName
		const currentTab = hamburgerMenu.filter(tab => tab.title == tabName)

		setActiveTab(currentTab[0]);
		// if index is passed in then it is mobile view so we need these: 
		if(index >= 0) {
			// setOpen(!isOpen)
			// if activeIndex is the same as index then toggle & close
			activeIndex === index ? setActiveIndex(null) : setActiveIndex(index)
		}
	};

	return (
		<>
			<MainMenuStyles
				role='button'
				tabIndex='0'
				className={`menu ${navbarOpen ? `open` : ''}`}
				// onClick={() => setNavbarOpen(false)}
				>
				<FaTimes className='closeBtn' onClick={() => setNavbarOpen(false)} />
	
				<TabbedContent 
					navContent={navContent}
					tabContent={<Tab 
						activeTab={activeTab} 
						// hide={hide} 
						// setFormOptions={setFormOptions} 
						// allHotels={allHotels}
						fullMenu={hamburgerMenu}
						setNavbarOpen={setNavbarOpen}
            isMainMenu={true}
					/>}
					handleClick={handleClick}
					activeTab={activeTab.title}
					fullscreen={true}
					// isOpen={isOpen}
					activeIndex={activeIndex}
					layout='horizontal'
				/>

			</MainMenuStyles>
		</>
	);
};

export default MainMenu;
