// Import Core
import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
// Import Components
import { Button } from "../ui/Button";
// Import Utils
import { getSlug } from "../../utils/getSlug";
import { toSlug } from "../../utils/toSlug";
import { displayLocation } from "../../utils/displayLocation";
import {
  getFlattenedHotels,
  getFlattenedPillar,
  getFlattenedBlog,
  filterHotelsFromSearchValue,
  getFlattenedSpecialOffers,
} from "../../utils/getSearchDatajs";
// Import Logic
import { getHotelsOrPagesFromIds } from "../../utils/logic/getHotelsOrPagesFromIds";
import RootContext from "../../context/RootContext";
import { useMediaQuery } from "react-responsive";

const SearchStyle = styled.div`
  position: relative;
  @media (max-width: 992px) {
    display: none;
  }
  .search-input {
    .search-icon-button,
    .clear-btn {
      position: absolute;
      font-size: 12px;
      color: var(--neutral-600);
      z-index: 2;
      height: 40px;
    }
    .search-icon-button,
    .clear-btn {
      left: 10px;
    }
    .clear-btn {
      right: 10px;
      left: unset;
      top: 0;
      cursor: pointer;
    }
    input {
      padding-left: 32px;
      box-shadow: none;
      border-radius: 0px;
      &:focus {
        outline: transparent;
      }
      @media (max-width: 1200px) {
        width: 175px;
      }
    }
    .wrapper {
      ul {
        li {
          svg {
            display: none;
          }
        }
      }
    }
  }
  .instant-search-wrapper {
    position: absolute;
    top: 0;
    .instant-search-results {
      position: absolute;
      /* margin-top: 5px; */
      margin-top: 0;
      top: 0;
      left: -12px;
      /* top: 75px; */
      min-width: 230px;
      width: 300px;
      max-height: 200px;
      height: fit-content;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      overflow: hidden;
      overflow-y: auto;

      border-right: 12px solid var(--primary-500);
      border-bottom: 10px solid var(--primary-500);
      border-left: 12px solid var(--primary-500);
      border-radius: 0px 0px 8px 8px;

      /* &::-webkit-scrollbar {
				display: none;
			} */

      .search-result {
        max-width: 100%;
        height: fit-content;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: var(--neutral-900);
        text-decoration: none;
        padding: 8px 16px;
        &:hover {
          color: var(--white);
          background: var(--primary-500);
        }
      }
    }
  }
`;

// Filter through flattened object & return results that match inputted search word
// const filterObject = (arr, searchKey) => arr.filter((obj) =>
// 	Object.keys(obj).some((key) => obj[key].includes(searchKey.toLowerCase()))
// );

// {searchChange}
const NavSearchInput = ({ icon, placeholder, searchValue, setSearchValue }) => {
  const { allHotels, allPillarPages, allBlogPosts, allSpecialOffers } =
    useContext(RootContext);

  const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

  // Store results from input here
  const [filteredData, setFilteredData] = useState([]);

  // ! FIX: THERE HAS TO BE A NICER WAY BUT I'M ALL ABOUT EASY AND QUICK RIGHT NOW

  // TODO: SEARCH PILLAR & BLOGS!!!!
  // Create a new hotel object so it is not nested and only contains information needed to search
  // const flattenedHotels = useContext(GHGContext)
  const flattenedHotels = getFlattenedHotels(allHotels);

  let flattenedPillar = getFlattenedPillar(allPillarPages);
  // remove any null / empty values
  flattenedPillar = flattenedPillar.map((obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
  );

  let flattenedBlog = getFlattenedBlog(allBlogPosts);
  // remove any null / empty values
  flattenedBlog = flattenedBlog.map((obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
  );

  let flattenedSpecialOffers = getFlattenedSpecialOffers(allSpecialOffers);

  // TODO: SPECIAL OFFERS ARCHIVE?

  const handleInputOnChange = (e) => {
    setSearchValue(e.target.value);
    let wordEntered = e.target.value;

    const searchInputHotelResults = filterHotelsFromSearchValue(
      flattenedHotels,
      wordEntered
    ).map((hotel) => hotel.id);

    const searchInputPillarResults = filterHotelsFromSearchValue(
      flattenedPillar,
      wordEntered
    ).map((hotel) => hotel.id);

    const searchInputBlogResults = filterHotelsFromSearchValue(
      flattenedBlog,
      wordEntered
    ).map((hotel) => hotel.id);

    const searchInputSpecialOffersResults = filterHotelsFromSearchValue(
      flattenedSpecialOffers,
      wordEntered
    ).map((hotel) => hotel.id);

    // Then get full hotel details from id
    const hotelSearchResults = getHotelsOrPagesFromIds(
      allHotels,
      searchInputHotelResults
    );
    const pillarSearchResults = getHotelsOrPagesFromIds(
      allPillarPages,
      searchInputPillarResults
    );
    const blogSearchResults = getHotelsOrPagesFromIds(
      allBlogPosts,
      searchInputBlogResults
    );

    const specialOfferSearchResults = getHotelsOrPagesFromIds(
      allSpecialOffers,
      searchInputSpecialOffersResults
    ).map((specialOffer) => {
      return {
        ...specialOffer,
        slug: {
          current:
            "review/" +
            getHotelsOrPagesFromIds(allHotels, [specialOffer.hotelId._id])[0]
              .slug.current,
        },
      };
    });

    // Make sure that the state updates everytime the value is changed
    if (wordEntered === "") {
      setFilteredData([]);
    } else {
      setFilteredData(
        hotelSearchResults.concat(
          pillarSearchResults.concat(
            blogSearchResults.concat(specialOfferSearchResults)
          )
        )
      );
    }
  };

	const handleInputOnSubmit = () => {
		console.log(searchValue);
		window.dataLayer.push({'event':'free_text_search','search_term': `${toSlug(searchValue)}`});
		if (searchValue.length > 0) {
			// Push to array of results that we will pass through to the searh-results page
			// navigate('/search-results', { state: { hotelSearchResults } });
			navigate(
				searchValue
					? `/search-results?value=${toSlug(searchValue)}`
					: '/search-results'
			);
			clearInput();
		}
	};

  // If the X in the input box is checked, clear the input and reset the state
  const clearInput = () => {
    setFilteredData([]);
    setSearchValue("");
    // Trigger a click on mobile search button to hide collapsible search
    if (isMobile) {
      document.getElementById("collapsible-search-btn").click();
    }
  };

  /* Render Component */
  return (
    <SearchStyle>
      <div className="search-input">
        <Button
          onClick={() => handleInputOnSubmit()}
          role="link"
          className="text-btn search-icon-button"
        >
          {icon}
        </Button>
        <input
          type="search"
          placeholder={placeholder}
          className=""
          value={searchValue}
          onChange={handleInputOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleInputOnSubmit();
            }
          }}
        />
        {/* {filteredData.length > 0 && (
					<FaTimes className='clear-btn' onClick={clearInput} />
				)} */}
      </div>

      {/* This is the instant search results box on change */}
      <div className="instant-search-wrapper">
        {filteredData.length > 0 && (
          <div className="instant-search-results">
            {/* Limit results to 15 so we're not scrolling too much */}
            {filteredData.slice(0, 15).map((item, key) => {
              const itemSlug = getSlug(item);
              if (item._type === "hotel") {
                return (
                  <Link
                    to={itemSlug}
                    className="search-result"
                    key={key}
                    onClick={() => clearInput()}
                  >
                    <h4 className="card_title">{item.name}</h4>
                    {item.addressGroup &&
                      (item.addressGroup.town || item.addressGroup.county) && (
                        <p className="small">
                          {/* Display the town, ciry, country if exist*/}
                          {displayLocation(
                            item.addressGroup.town,
                            item.addressGroup.county,
                            item.addressGroup.country
                          )}
                        </p>
                      )}
                  </Link>
                );
              } else if (item._type === "pillar") {
                return (
                  <Link
                    to={itemSlug}
                    className="search-result"
                    key={key}
                    onClick={() => clearInput()}
                  >
                    <h4 className="card_title">{item.name}</h4>
                    {/* <p className='small'>Page</p> */}
                  </Link>
                );
              } else if (item._type === "blog") {
                return (
                  <Link
                    to={itemSlug}
                    className="search-result"
                    key={key}
                    onClick={() => clearInput()}
                  >
                    <h4 className="card_title">{item.name}</h4>
                    <p className="small">Blog Post</p>
                  </Link>
                );
              } else if (item._type === "specialOffer") {
                return (
                  <Link
                    to={itemSlug}
                    className="search-result"
                    key={key}
                    onClick={() => clearInput()}
                  >
                    <h4 className="card_title">{item.title}</h4>
                    <p className="small">Special Offer</p>
                  </Link>
                );
              }
            })}
          </div>
        )}
      </div>
    </SearchStyle>
  );
};

export default NavSearchInput;
