// Import Core
import React, { useState } from 'react';
import { Link } from 'gatsby';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
// Import Components
import Nav from './Nav';
import NavSearchInput from './NavSearchInput';
import MainMenu from '../ui/MainMenu';
// Import Icons
import { FaSearch } from 'react-icons/fa';

/* Styled Components */
const HeaderStyle = styled.header`
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	.header_wrapper {
		background: var(--primary-500);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		align-items: center;
		padding: 16px 40px;
		gap: 12px;
		.logo {
			/* width: 300px; */
			/* max-width: 300px;
			min-width: 200px;
			width: 100%; */
			width: clamp(150px, 100%, 300px);
			/* @media (max-width: 375px) {
				display: none;
			} */
			/* &.mobile {
				display: none;
				@media (max-width: 375px) {
					display: block;
					height: 75px;
					width: 75px;
				}
			} */
		}
		@media (max-width: 768px) {
			padding: 16px 24px;
		}
	}
`;

/* Render Component */
const Header = ({ logo, searchValue, setSearchValue, mainNav, hamburgerMenu }) => {
	const [navbarOpen, setNavbarOpen] = useState(false);

	return (
		<HeaderStyle>
			<div className='header_wrapper'>
				<Link to='/'>
					<GatsbyImage
						image={getImage(logo)}
						alt='Good Hotel Guide'
						className='logo'
					/>
					{/* <GatsbyImage
						image={getImage(logoMobile)}
						alt='Good Hotel Guide'
						className='logo mobile'
					/> */}
				</Link>
				{/* Search */}
				<NavSearchInput
					placeholder='Search this site...'
					icon={<FaSearch />}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
				/>

				<Nav navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} mainNav={mainNav} searchValue={searchValue} setSearchValue={setSearchValue} />

				<MainMenu navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} hamburgerMenu={hamburgerMenu} />
			</div>
		</HeaderStyle>
	);
};

export default Header;
