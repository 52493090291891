// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Libraries
import Collapsible from 'react-collapsible';

/* Styled Components */
const CollapsibleStyles = styled(Collapsible)`
	width: 100%;
	position: relative;
`;

/* Render Component */
const CollapsibleDropdown = ({
	trigger,
	content,
	className,
	triggerWhenOpen,
}) => {
	return (
		<CollapsibleStyles
			trigger={trigger}
			className={className}
			triggerWhenOpen={triggerWhenOpen}>
			{content}
		</CollapsibleStyles>
	);
};

export default CollapsibleDropdown;
