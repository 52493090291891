// Import Core
import React from 'react';
// Import Utils
import { GetLinks } from '../../utils/getNavItemLinks';

const PortableTextComponent = {
  types: {
    imageItem: ({value}) => <><img src={value.content.asset.url} alt={value.alt} className='portable-text_image' /><figcaption className='small'>{value.caption}</figcaption></>,
    image: ({value}) => <><img src={value.asset.url} alt={value.alt} className='portable-text_image' /><figcaption className='small'>{value.caption}</figcaption></>,
    navItem: ({value}) => <GetLinks navItem={value} />,
    // image: SampleImageComponent,
    callToAction: ({value, isInline}) =>
      isInline ? (
        <a href={value.url}>{value.text}</a>
      ) : (
        <div className="callToAction">{value.text}</div>
      ),
  },

  marks: {
    link: ({children, value}) => {
      const rel = !value && !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      )
    },
  },
}

export default PortableTextComponent