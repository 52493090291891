// Import Utils
import isHotelInternational from "./isHotelInternational"
import transformToUpperCase from "./transformToUpperCase"

const displayLocation = (town, county, country) => {

let townString, countyString, countryString

  if(town) {
    townString = transformToUpperCase(town.toLowerCase(), ['-'])
  }
  
  if(county) {
    countyString = transformToUpperCase(county.toLowerCase(), ['-']) 
  }

  if(isHotelInternational(country)) {
    countryString = country
  }

  let locationString = ''
  if(townString && countyString) {
    locationString = `${townString}, ${countyString}`
  } else if(townString && !countyString) {
    locationString = townString
  } else if(!townString && countyString) {
    locationString = countyString
  }

  if(countryString) {
    if(locationString.length > 0) {
      locationString = `${locationString}, ${countryString}`
    } else {
      locationString = countryString
    }
  }

  return locationString
}

export {displayLocation}