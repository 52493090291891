import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
// Import Icons
import { FaTimes } from 'react-icons/fa';

/* Styled Components */
const ModalStyles = styled.div`
	position: relative;
	.modal_overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100vw;
		height: 100vh;
		background-color: var(--black);
		opacity: 0.5;
	}

	.modal {
		z-index: 3;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 40px 32px 32px 32px;
		background: var(--white);
		border: none;
		border-radius: 4px;
		min-width: 400px;
		width: 100%;
		max-width: 500px;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
		&.fullscreen {
			max-width: 90vw;
			max-height: 90vh;
			background-color: var(--neutral-100);
		}
		.modal_close-button {
			position: absolute;
			top: 16px;
			right: 16px;
			cursor: pointer;
			color: var(--black);
			opacity: 0.5;
		}

		@media (max-width: 768px) {
			min-width: unset;
			max-width: 75vw;
			max-height: 90vh;
			overflow-y: auto;
		}
	}

	.modal {
		.section-title {
			padding: 30px 30px 0 30px;
		}
		> div {
			form {
				padding: 30px;
			}
		}
	}
`;

/* Render Component */
const Modal = ({ isModalOpen, hide, content, fullscreen }) =>
	isModalOpen
		? createPortal(
				<ModalStyles>
					{/* TODO TRY TO ADD TO OVERLAY onClick={hide} onKeyDown={hide} and define role? */}
					<div
						className={`modal_overlay ${isModalOpen ? 'isModalOpen' : ''}`}
					/>
					<div
						className={`modal ${fullscreen ? 'fullscreen' : ''}`}
						aria-modal
						aria-hidden
						tabIndex={-1}
						role='dialog'>
						<FaTimes
							className='modal_close-button'
							data-dismiss='modal'
							aria-label='Close'
							onClick={hide}
						/>
						<div>{content}</div>
					</div>
				</ModalStyles>,
				document.body
		)
		: null;

export default Modal;
