// Import Core
import { useState } from 'react';

const useDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null)

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  
  const handleDropdownClick = (id) => {
    console.log(id)
    selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
    setDropdownOpen(!isDropdownOpen);
  }

  return [
    isDropdownOpen,
    toggleDropdown,
    handleDropdownClick,
    selectedItem, 
    setSelectedItem
  ]
  
};

export default useDropdown;