const isHotelInternational = (hotel) => {
  // if country is not "England", "Wales", "Scotland", "Ireland", "Channel Islands" or "Northern Ireland"

  if(hotel === "England" || hotel === "Wales" || hotel === "Scotland" || hotel === "Ireland" || hotel === "Channel Islands" || hotel === "Northern Ireland" ) {
    return false      
  } else {
    return true
  }
}

export default isHotelInternational;