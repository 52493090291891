// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Icons
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

/* Styled Components */
const TabbedContentStyles = styled.div`
	display: flex;
	width: fit-content;
	flex: 1;
	max-height: 90vh;
	overflow-y: auto;

	@media (max-width: 768px) {
		display: none;
	}

	li {
		list-style: none;
		cursor: pointer;
		&:hover {
		}
	}

	.nav {
		text-transform: uppercase;
		width: 272px;
		max-width: 272px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		flex: 1;
		max-height: 90vh;
		overflow-y: auto;

		li {
			padding: 16px 24px;
			background-color: var(--neutral-50);
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 500;
			font-size: 15px;
			color: var(--neutral-700);
			&.active {
				background-color: var(--white);
				color: var(--primary);
			}
			&:hover {
				background-color: var(--white);
			}
		}
	}

	.content {
		padding: 40px 36px;
		columns: 2;
		background-color: var(--white);
		width: 100%;
		display: flex;
		/* flex-direction: column; */
		gap: 16px;
		flex-wrap: wrap;
		flex: 2;
		overflow-y: auto;

		> div {
			flex: 1;
			height: fit-content;
			h5 {
				font-weight: 500;
				color: var(--primary);
				margin-bottom: 16px;
			}
			ul {
				display: flex;
				flex-direction: column;
				gap: 16px;
				li {
					font-size: 0.778rem; //14px
					line-height: 150%;
					color: var(--neutral-900);
					a {
						text-decoration: none;
						color: var(--neutral-900);
					}
				}
			}

			@media (max-width: 768px) {
			}
		}
	}

	&.fullscreen {
		width: calc(100% - 240px);
		padding: 64px 120px;

		@media (max-width: 992px) {
			width: calc(100% - 48px);
			padding: 40px 24px;
			flex-wrap: wrap;
		}
	}

	&.vertical {
		max-height: 100%;
		flex-direction: column;
		overflow-y: unset;
		width: 100%;
		@media (max-width: 768px) {
			display: flex;
		}

		.nav {
			flex-direction: row;
			max-width: 100%;
			max-height: unset;
			justify-content: center;
			align-items: center;
			align-self: stretch;
			gap: 0;

			padding: 64px 120px;
			width: calc(100% - 240px);

			@media (max-width: 992px) {
				padding: 64px;
				width: calc(100% - 128px);
			}

			@media (max-width: 768px) {
				padding: 40px 0 0;
				margin: 0 0 40px 40px;
				display: flex;
				justify-content: flex-start;
				width: calc(100% - 40px);

				/* Scrollbar Styles */
				::-webkit-scrollbar {
					height: 8px;
				}
				::-webkit-scrollbar-thumb {
					background-color: var(--neutral-300);
				}
			}

			li {
				width: fit-content;
				background-color: transparent;
				flex: unset;
				white-space: nowrap;
				transition: box-shadow 0.3s ease-in-out;
				svg {
					display: none;
				}
				&:hover {
					box-shadow: 0px -2px 0px 0px var(--neutral-700) inset;
				}
				&.active {
					box-shadow: 0px -2px 0px 0px var(--primary) inset;
				}
			}
		}

		.content {
			/* padding: 0; */
			columns: unset;

			padding: 0 120px 64px;
			width: calc(100% - 240px);

			@media (max-width: 992px) {
				padding: 0 64px 64px;
				width: calc(100% - 128px);
				flex-wrap: wrap;
			}

			@media (max-width: 768px) {
				padding: 0 40px 40px;
				width: calc(100% - 80px);
			}

			> div {
				@media (max-width: 992px) {
					width: 273px;
					flex: auto;
				}
				h5 {
					color: var(--primary);
					font-size: 18px;
					font-weight: 600;
					line-height: 150%;
				}
				ul {
					li {
						a {
							text-decoration: underline;
							transition: color 0.3s ease-in-out;
							p {
								font-size: 18px;
								font-weight: 300;
								line-height: 160%;
							}
							&:hover {
								color: var(--neutral-800);
							}
						}
					}
				}
			}
		}
	}
`;

// Mobile Accordion View
const TabbedContentMobileStyles = styled.div`
	display: none;
	max-height: 90vh;
	overflow-y: auto;
	width: 100%;
	padding: 4px 24px;
	flex-direction: column;
	gap: 8px;

	&.fullscreen {
		margin: 100px 0 110px;
	}

	@media (max-width: 768px) {
		display: flex;
		.accordion-wrapper {
			.accordion-item {
				overflow: hidden;
				transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
				height: auto;
				max-height: 9999px;
				width: 100%;
				&.collapsed {
					max-height: 0;
					transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
				}
			}

			.accordion-title {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				gap: 16px;
				background: var(--white);
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
				border-radius: 0px;
				cursor: pointer;
				color: var(--neutral-700);
				padding: 4px 24px;
				text-transform: uppercase;

				span {
					border: 1px solid var(--neutral-300);
					border-radius: 4px;
					width: 56px;
					height: 56px;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: transform 0.1s ease-in-out;
				}

				&:hover,
				&.open {
					color: black;
				}

				&.open {
					span {
						transform: rotate(180deg);
					}
				}
			}

			.accordion-content {
				padding: 24px;
				background-color: var(--white);
				width: calc(100% - 48px);
				display: flex;
				flex-direction: column;
				gap: 16px;
				overflow-y: auto;

				> div {
					height: fit-content;
					h5 {
						font-weight: 500;
						color: var(--primary);
						margin-bottom: 16px;
					}
					ul {
						display: flex;
						flex-direction: column;
						gap: 16px;
						list-style-type: none;
						li {
							font-size: 0.778rem; //14px
							line-height: 150%;
							color: var(--neutral-900);
							a {
								text-decoration: none;
								color: var(--neutral-900);
							}
						}
					}
				}
			}
		}
		&.vertical {
			display: none;
		}
	}
`;

/* Render Component */
const TabbedContent = ({
	navContent,
	tabContent,
	handleClick,
	activeTab,
	fullscreen,
	activeIndex,
	layout,
}) => {
	return (
		<>
			<TabbedContentStyles
				className={`${layout} ${fullscreen ? 'fullscreen' : ''}`}>
				<ul className='nav'>
					{/* Create the map list items */}
					{navContent.map((item, index) => {
						const isActive = activeTab == item ? 'active' : null;
						return (
							<li
								key={index}
								id={item}
								className={isActive}
								onClick={handleClick}>
								<span>{item}</span>
								<FaChevronRight />
							</li>
						);
					})}
				</ul>
				{/* Render tabbed content */}
				<div className='content'>{tabContent}</div>
			</TabbedContentStyles>

			{/* Mobile View is an accordion */}
			<TabbedContentMobileStyles
				className={`${layout} ${fullscreen ? 'fullscreen' : ''}`}>
				{navContent.map((item, index) => {
					const isActive = index === activeIndex;
					return (
						<div key={index} className='accordion-wrapper'>
							<div
								className={`accordion-title ${isActive ? 'open' : ''}`}
								onClick={(e) => handleClick(e, index)}
								key={index}
								id={item}>
								{item}
								<span>
									<FaChevronDown />
								</span>
							</div>
							<div className={`accordion-item ${!isActive ? 'collapsed' : ''}`}>
								<div className='accordion-content'>{tabContent}</div>
							</div>
						</div>
					);
				})}
			</TabbedContentMobileStyles>
		</>
	);
};

export default TabbedContent;
